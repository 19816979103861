import React, { useState } from 'react';
import { FaArrowRight, FaChevronDown } from 'react-icons/fa';
import { FiMenu } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../images/logo.png';
import { navLinks } from '../utils/data';
import Button from './CommonSection/Button';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  return (
    <header className="relative z-40 flex justify-between items-center px-5 min-[1700px]:px-20 py-6 h-[90px] mx-auto container">
      <Link className="font-bold text-2xl" aria-current="page" to="/">
        <img
          src={Logo}
          width={100}
          height={30}
          alt="MutaEngine"
          className="rounded-lg"
        />
      </Link>

      {/* Mobile menu button */}
      <div className="md:hidden">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="text-white focus:outline-none"
        >
          <FiMenu className="w-5 h-5" />
        </button>
      </div>

      {/* Desktop Menu */}
      <nav className="hidden md:flex absolute pt-4 gap-6 left-1/2 -translate-x-1/2">
        {navLinks.map((link, index) => (
          <div className="flex group" key={index}>
            <div className="relative flex mb-3 group">
              <Link
                className="group-hover:text-blue flex items-center gap-1 md:text-sm lg:text-lg transition-all ease-in"
                aria-current="page"
                to={link?.href}
              >
                {link?.label}
                {link?.subLink && <FaChevronDown className="w-3.5 h-3.5" />}
              </Link>

              {/* SubLinks (Dropdown) */}
              {link?.subLink && link.subLink.length > 0 && (
                <div
                  className={`hidden group-hover:block top-5 absolute left-0 mt-2 bg-white shadow-lg rounded-lg z-10`}
                >
                  <div className="flex flex-col gap-4 bg-[#00040f] shadow-buttonShadow p-4 border border-blue rounded-lg w-[200px]">
                    {link.subLink.map((sublink, subIndex) => (
                      <Link
                        key={subIndex}
                        className="flex flex-1 justify-between items-center text-white hover:text-blue tracking-wide"
                        to={sublink?.href}
                      >
                        {sublink?.label}
                        <FaArrowRight />
                      </Link>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </nav>

      {/* Mobile Menu */}
      {isOpen && (
        <nav className="top-16 left-0 absolute flex flex-col gap-y-4 md:hidden bg-black p-6 w-full text-white zindexx9">
          {navLinks.map((link, index) => (
            <div className="flex flex-col items-center group" key={index}>
              <div className="relative flex mb-3 text-center group">
                <Link
                  className="group-hover:text-blue flex items-center gap-1 text-lg transition-all ease-in"
                  aria-current="page"
                  to={link?.href}
                  onClick={() => {
                    if (link?.subLink) {
                      setIsOpen(!isOpen);
                      setShowDropdown(false);
                    } 
                    else setIsOpen(!isOpen);
                  }}
                >
                  {link?.label}
                </Link>
                {link?.subLink && (
                  <FaChevronDown
                    className="w-3.5 h-3.5 ml-2 my-auto"
                    onClick={() => setShowDropdown(!showDropdown)}
                  />
                )}

                {/* SubLinks (Dropdown) */}
                {showDropdown && link?.subLink && link.subLink.length > 0 && (
                  <div
                    className={`block top-5 absolute w-full right-[70%] mt-2 bg-white shadow-lg rounded-lg z-10`}
                  >
                    <div className="flex flex-col gap-4 bg-[#00040f] shadow-buttonShadow p-4 border border-blue rounded-lg w-[200px]">
                      {link.subLink.map((sublink, subIndex) => (
                        <Link
                          key={subIndex}
                          className="flex flex-1 justify-between items-center text-white hover:text-blue tracking-wide"
                          to={sublink?.href}
                          onClick={() => {
                            setShowDropdown(false);
                            setIsOpen(false);
                          }}
                        >
                          {sublink?.label}
                          <FaArrowRight />
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}

          {/* Login and Sign Up for Mobile */}
          <div className="flex flex-col gap-4">
            <Button
              title="Login"
              fullWidth
              variant={'outlined'}
              onClick={() => {
                navigate('/login');
                setIsOpen(!isOpen);
              }}
            />

            <Button
              title="Sign up"
              fullWidth
              variant={'contained'}
              onClick={() => {
                navigate('/signup');
                setIsOpen(!isOpen);
              }}
            />
          </div>
        </nav>
      )}

      <div className="md:flex gap-4 hidden">
        <Button
          title="Login"
          variant={'outlined'}
          onClick={() => navigate('/login')}
          className={'md:text-sm lg:text-base'}
        />

        <Button
          title="Sign up"
          variant={'contained'}
          onClick={() => navigate('/signup')}
          className={'md:text-sm lg:text-base'}
        />
      </div>
    </header>
  );
};

export default Navbar;