import React from "react";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const Pricing = () =>{
    const navigate = useNavigate();
    const plans = [
        {
          name: "MutaIntegri",
          price: "₹ 999",
          features: [
            "Continuous integrity checks for obfuscated files",
            "Real-time alerts and detailed reports on file modifications",
            "Seamless integration with existing security protocols",
            "User-friendly dashboard for easy management and monitoring",
          ],
          highlighted: false,
        },
        {
          name: "MutaCryptorLite",
          price: "₹ 1499",
          features: [
            "Encryption and decryption of unlimited obfuscated files",
            "Single-node processing for straightforward deployment",
            "Intuitive tools for file management",
            "24/7 customer support",
          ],
          highlighted: true,
        },
        {
          name: "MutaCryptorPro",
          price: "₹ 2499",
          features: [
            "Advanced encryption and decryption for obfuscated files",
            "Unlimited file support for large-scale operations",
            "Multi-node processing for high performance and speed",
            "Comprehensive enterprise-level management tools",
            "Priority customer support",
          ],
          highlighted: false,
        },
      ];
    
      return (
        <div className="flex flex-col">
          <div className="flex items-center justify-center mb-[5%]">
                    <div className="text-white text-4xl px-8 py-4 border-2 border-[#3ED5DD] rounded-2xl shadow-lg" style={{ boxShadow: 'rgba(0, 255, 255, 0.5) 0px -2px 4px 1px' }}>
                       Pricing
                    </div>
                </div>
          
        <div className="flex flex-col md:flex-row justify-center items-stretch gap-12 p-6">
          
          {plans.map((plan, index) => (
            <div
              key={plan.name}
              className={`w-full md:w-80 flex flex-col p-6 ${
                plan.highlighted
                  ? "bg-[#1716A8] text-white"
                  : "bg-white text-black-900"
              } shadow-lg rounded-lg transition-transform duration-300 ${
                index === 1 ? "transform -translate-y-8 z-10" : ""
              }`}
            >
              <div className="p-4 border-b border-gray-200">
                <h3 className="text-2xl font-bold text-center" style={plan.highlighted ? { color: "" } : { color: "#0B0914" }}>{plan.name}</h3>
              </div>
              <div className="flex-grow p-4 flex flex-col justify-between">
                <div>
                  <p className="text-sm mb-4 Montserrat" style={plan.highlighted ? { color: "" } : { color: "#A9A9AA" }}>What You'll Get</p>
                  <ul className="space-y-2">
                    {plan.features.map((feature, featureIndex) => (
                      <li key={featureIndex} className="flex items-start">
                        <FaCheck className="mr-2 mt-[3px] h-4 w-4 bg-white p-1 rounded-full flex-shrink-0" style={plan.highlighted ? { fill: "#1716A8" } : { background: "#35353F" }}/>
                        <span className="text-sm Montserrat" style={plan.highlighted ? { color: "" } : { color: "#35353F" }}>{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="flex flex-col items-start p-4 border-t border-gray-200">
                <p className="text-3xl font-bold mb-2" style={plan.highlighted ? { color: "" } : { color: "#35353F" }}>
                  {plan.price}
                  <span className="text-sm font-normal " style={plan.highlighted ? { color: "" } : { color: "#35353F" }}>/year</span>
                </p>
                <button
                  onClick={() => navigate(`/${plan.name}/checkout`)}
                  className={`w-full py-2 ${
                    plan.highlighted
                      ? "bg-white text-[#1716A8] hover:bg-gray-200"
                      : "bg-[#1716A8] text-white hover:bg-[#04036F]"
                  } rounded-lg Montserrat`}
                >
                  Choose
                </button>
              </div>
            </div>
          ))}
        </div>
        </div>
      );
}

export default Pricing;
