import { useEffect, useState } from 'react';
import Button from '../CommonSection/Button';

const PaymentSuccess = () => {
    const [orderDetails, setOrderDetails] = useState({
      orderNumber: '',
      date: '',
      paymentMethod: '',
      subtotal: '',
      taxes: '',
      total: '',
      email: '',
      product: ''
    });
  
    useEffect(() => {
      const fetchOrderDetails = async () => {
        const data = {
          orderNumber: '96465',
          date: '6 November, 2024',
          paymentMethod: 'Credit Card',
          subtotal: 67,
          taxes: 11,
          total: 88,
          email: 'sales@gmail.com',
          product: 'MutaCryptor Pro'
        };
  
        setOrderDetails(data);
      };
  
      fetchOrderDetails();
    }, []); 

    return (
      <div className="min-h-screen text-white flex flex-col items-center justify-center px-4 py-12">
        <div className="absolute top-1/4 left-[-190px] w-[250px] h-[250px] bg-white blur-[150px] opacity-60"></div>
  
        {/* Order Confirmed Header */}
        <div className="mb-8">
          <h2 className="text-3xl tab:text-5xl border-2 border-cyan-500 py-2 px-4 rounded-md text-center font-semibold">
            Order Confirmed
          </h2>
        </div>
  
        {/* Thank you for your order */}
        <div className="text-center mb-6 tab:w-[600px] desk:tab:w-[660px] pb-5">
          <h1 className="text-3xl tab:text-4xl font-bold">Thank you for your order!</h1>
          <p className="text-gray-400 mt-4 text-lg ">
            We sent a confirmation email at <span className='text-white'>{orderDetails.email}</span>. The license key has been sent to the email address. Below you will find all the information about your order.
          </p>
        </div>
  
        {/* Order Details */}
        <div className="border border-purple-500 rounded-lg shadow-lg p-6 mb-12 w-full tab:w-[600px] desk:w-[800px]">
          <h3 className="text-3xl font-semibold mb-4">Order details</h3>
          <div className="flex justify-between py-2">
            <span>Order number</span>
            <span>{orderDetails.orderNumber}</span>
          </div>
          <div className="flex justify-between py-2">
            <span>Date</span>
            <span>{orderDetails.date}</span>
          </div>
          <div className="flex justify-between border-b-2 border-dashed border-gray-300 pt-2 pb-5">
            <span>Payment Method</span>
            <span>{orderDetails.paymentMethod}</span>
          </div>
          <div className="flex justify-between pt-5 pb-2">
            <span>Subtotal</span>
            <span>${orderDetails.subtotal}</span>
          </div>
          <div className="flex justify-between py-2">
            <span>Taxes VAT [20%]</span>
            <span>${orderDetails.taxes}</span>
          </div>
          <div className="flex justify-between py-2 font-semibold text-lg">
            <span>Total</span>
            <span>${orderDetails.total}</span>
          </div>
  
          {/* Download Invoice Button */}
          <div className="text-center mt-6">
            <Button
              title="Download Invoice"
              variant="contained"
            />
          </div>
        </div>
  
        {/* Download Product */}
        <div className="text-center mb-6">
          <h2 className="text-4xl font-semibold mb-4">Download your Product</h2>
          <p className="text-gray-400">
            {`Click on the button to download the ${orderDetails.product} Product subscription.`}
          </p>
  
          {/* Download Button */}
          <div className="mt-4">
            <Button
              title="Download"
              variant="contained"
            />
          </div>
        </div>
  
        {/* Contact Support */}
        <div className="text-center mt-6 text-gray-500 text-sm">
          <p>If you have any further questions, don’t hesitate to contact us at <a href="mailto:co@mutageninc.cloud" className="text-cyan-500">co@mutageninc.cloud</a> or call us on +91-987654320.</p>
          <p>We are happy to help you.</p>
        </div>
      </div>
    );
  };
  
  export default PaymentSuccess;