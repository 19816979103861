import React from 'react';
import { useNavigate } from 'react-router-dom';
import BubbleImage from '../../images/MainPage/Bubble.png';
import Button from '../CommonSection/Button';
import { HoverBorderGradient } from '../ui/hover-border-gradient';

const Hero = () => {
  const navigate = useNavigate();
  return (
    <main className="relative flex desk:flex-row flex-col justify-between items-center gap-20 desk:gap-0 tab:my-[70px] px-5">
      <div className="top-1/4 left-[-190px] absolute bg-white opacity-60 blur-[150px] w-[250px] h-[250px]"></div>
      <div className="flex flex-col justify-center items-center desk:items-start gap-6 w-full desk:w-1/2 text-center desk:text-left">
        <HoverBorderGradient>
          <b>Protecting Code, Preserving Innovation</b>
        </HoverBorderGradient>
        <h1 className="font-bold text-5xl lg:text-6xl leading-tight">
          Revolutionizing Software Security with{' '}
          <span className="text-cyan-400">Dynamic</span> Mutation
        </h1>
        <p className="text-xl Montserrat">
          Introducing the Polymorphic Code Engine - A New Era in Protecting Your Software.
        </p>
        <div className="space-x-4">
          <Button title="Learn More" variant={'contained'} onClick={() => navigate('/about')} />
          <Button title="Start Now" variant={'outlined'} onClick={() => navigate('/login')} conicGradient={true} />
        </div>
      </div>
      <div className="mt-10 md:mt-0 mobileW100">
        <img
          src={BubbleImage}
          alt="Abstract 3D shape representing dynamic mutation"
          className="mx-auto w-full max-w-md"
          style={{ height: 400, width: 400 }}
        />
      </div>
    </main>
  );
};

export default Hero;
