import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

// Updated data structure with both heading and description
const descriptions = [
  {
    heading: 'Polymorphic Code',
    text: 'MutaEngine constantly mutates your software’s code in real-time, making it nearly impossible for hackers to decompile or reverse-engineer.',
  },
  {
    heading: '360° Security',
    text: 'Our multi-layered defense mechanisms ensure that every part of your software is protected, from code to data to user transactions.',
  },
  {
    heading: 'Web3 Integration',
    text: 'Fully compatible with decentralized applications, MutaEngine provides robust security for your blockchain projects.',
  },
];

const BubbleAnimation = () => {
  const [scrollY, setScrollY] = useState(0); // Tracks the scroll position

  // Tracks scroll position on window scroll
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Determine the index of the centered bubble based on scroll position
  const currentIndex = Math.floor(scrollY / 150) % descriptions.length;

  // Animation variants for left, center, and right positions
  const variants = {
    left: { x: '-100vw', opacity: 0 },
    center: { x: 0, opacity: 1, scale: 1 },
    right: { x: '100vw', opacity: 0 },
  };

  return (
    <div className="relative flex justify-center items-center h-[30vh]">
      {/* Simple white connecting line */}
      <div className="z-0 absolute bg-white w-full h-0.5 transform -translate-y-1/2"></div>

      {descriptions.map((description, index) => {
        // Calculate the position of the bubble (left, center, or right)
        let position = 'right';
        if (index === currentIndex) {
          position = 'center';
        } else if (index === (currentIndex + 1) % descriptions.length) {
          position = 'right';
        } else if (
          index ===
          (currentIndex - 1 + descriptions.length) % descriptions.length
        ) {
          position = 'left';
        }

        return (
          <motion.div
            key={index}
            className="z-10 absolute flex flex-col justify-center items-center bg-blue shadow-xl px-5 py-10 rounded-3xl w-64 h-64 font-bold text-center"
            initial={variants.right} // Start off the screen
            animate={variants[position]} // Animate based on position (left, center, or right)
            transition={{ duration: 1 }} // Transition duration for smoothness
          >
            <h2 className="mb-2 font-montserrat font-semibold text-3xl text-black">{description.heading}</h2> {/* Heading */}
            <p className="font-montserrat text-black">{description.text}</p> {/* Description */}
          </motion.div>
        );
      })}
    </div>
  );
};

export default BubbleAnimation;
