import React from 'react';
import TwoColSection from '../component/CommonSection/TwoColSection';
import Benefit from '../component/Solutions/Benefit';
import Details from '../component/Solutions/Details';
import Features from '../component/Solutions/Features';
import Getin from '../component/Solutions/Getin';
import img1 from '../images/MutaCryptorLite/Benefits1.png';
import img2 from '../images/MutaCryptorLite/Benefits2.png';
import img3 from '../images/MutaCryptorLite/Benefits3.png';
import Features1 from '../images/MutaCryptorLite/Features1.png';
import Features2 from '../images/MutaCryptorLite/Features2.png';
import Features3 from '../images/MutaCryptorLite/Features3.png';
import Features4 from '../images/MutaCryptorLite/Features4.png';
import Helix from '../images/MutaCryptorLite/helix.png';
import Product from '../images/MutaCryptorLite/Without Shadow/MUTACRYPTOR-temp.webp';

const MutaCryptorLite = () => {
  const Benefits = [
    {
      img: img1,
      description:
        'Ideal for small to medium-sized enterprises seeking to enhance security without investing in complex, costly infrastructure.',
    },
    {
      img: img2,
      description:
        'Provides a scalable solution that grows with your business, ensuring ongoing protection.',
    },
    {
      img: img3,
      description:
        'Easy to deploy and manage, saving time and resources for your team.',
    },
  ];

  const features = [
    {
      icon: Features1,
      title: 'Encryption and Decryption of Obfuscated Files',
      description:
        'Protects your sensitive data by securely encrypting and decrypting files, ensuring that only authorized users can access critical information.',
    },
    {
      icon: Features2,
      title: 'Unlimited File Support',
      description:
        'Scales seamlessly to support an unlimited number of files, making it perfect for growing businesses.',
    },
    {
      icon: Features3,
      title: 'Single-Node Processing',
      description:
        'Offers simplicity and ease of use by processing all encryption and decryption tasks on a single node, reducing the complexity of deploym',
    },
    {
      icon: Features4,
      title: 'Intuitive Management Tools',
      description:
        'Provides a straightforward interface for managing file security operations, allowing for easy adoption by IT teams.',
    },
  ];

  const plans = [
    {
      id: 1,
      name: 'MutaCryptor Lite',
      price: '$99',
      features: [
        'Ideal for: Small to medium-sized enterprises seeking scalable encryption solutions.',
        'Benefits: Cost-effective encryption, easy integration, scales as your business grows',
      ],
      highlighted: true,
      img: Helix,
    },
    {
      id: 2,
      name: 'MutaCryptor Lite',
      price: '₹ 1499',
      features: [
        'Encryption and decryption of unlimited obfuscated files',
        'Single-node processing for straightforward deployment',
        'Intuitive tools for file management',
        '24/7 customer support',
      ],
      highlighted: false,
    },
  ];

  return (
    <div>
      <div className="relative mx-auto container">
        <div className="top-1/4 left-[-190px] absolute bg-white opacity-60 blur-[150px] w-[250px] h-[250px]"></div>

        <div className='mb-[70px]'>
          {' '}
          <TwoColSection
            Image={Product}
            leftHeading={'What is the'}
            colorHeading={'MutaCryptor Lite'}
            rightHeading={'?'}
            des={
              'MutaCryptor Lite is an efficient and scalable encryption and decryption tool tailored for small to medium-sized enterprises. It offers robust security for obfuscated files, ensuring that sensitive data is protected without the need for complex infrastructure or high costs.'
            }
            // button={'Learn More'}
            // conicGradient={true}
            type={1}
            textAlgin={1}
            wid={62}
            mdWid={52}
          />
        </div>
      </div>
      <div className="font-bold text-3xl text-center sm:text-4xl md:text-5xl">
        Key Features
      </div>
      <Features features={features} />
      <div className="pt-12 font-bold text-3xl text-center sm:text-4xl md:text-5xl">
        Benefits
      </div>
      <Benefit features={Benefits} />
      <Details plans={plans} />
      <Getin />
    </div>
  );
};

export default MutaCryptorLite;
