import { Link } from "react-router-dom";
import Button from "../CommonSection/Button";

const PaymentFailed = () => {
  return (
    <div className="flex-grow flex flex-col justify-center items-center text-center px-4 py-20">
      <div className="absolute top-1/4 left-[-190px] w-[250px] h-[250px] bg-white blur-[150px] opacity-60"></div>
      <h1 className="text-4xl md:text-5xl font-bold mb-6 text-white">
        Oh no, your payment failed
      </h1>
      <p className="text-lg md:text-xl text-gray-400 mb-8 max-w-2xl">
        Hey there. We tried to charge your card but, something went wrong. Please update your payment method to continue.
      </p>

      {/* Update Payment Method button */}
      <Button 
        title="Update Payment Method" 
        variant="contained"
        className="px-12 tab:px-20"
      />

      {/* Support link */}
      <p className="text-sm text-gray-400 pt-3">
        Have a question? <Link to="/contact" className="font-semibold text-cyan-500 hover:text-cyan-300">Contact Support</Link>
      </p>
    </div>
  );
};

export default PaymentFailed;
