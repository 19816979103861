import { useState } from 'react';
import Button from '../component/CommonSection/Button';
import SphereKnotBG from '../component/CommonSection/SphereKnotBG';
import BusinessForm from '../component/ContactForms/BusinessForm';
import FeedbackForm from '../component/ContactForms/FeedbackForm';
import SupportForm from '../component/ContactForms/SupportForm';

const Contact = () => {
  const [activeTab, setActiveTab] = useState('feedback');

  const renderForm = () => {
    switch (activeTab) {
      case 'feedback':
        return <FeedbackForm />;
      case 'support':
        return <SupportForm />;
      case 'business':
        return <BusinessForm />;
      default:
        return <FeedbackForm />;
    }
  };

  return (
    <>
      {/* Sphere and Knot Background */}
      <SphereKnotBG />
      <div className="tab:block top-0 left-[200px] absolute hidden bg-purple-500 opacity-20 blur-[150px] w-[300px] h-[300px]"></div>
      <div className="top-40 tab:top-1/3 left-[-200px] absolute bg-purple-300 tab:opacity-20 blur-[40px] w-[50px] tab:w-[500px] h-[50px] tab:h-[500px]"></div>
        
      {/* Content Section */}
      <div className="relative z-20 mx-auto mb-12 desk:mb-40 tab:mb-20 desk:pt-20 tab:pt-10 w-11/12 desk:w-2/3 tab:w-3/4 text-center text-white container">
        <h1 className="mb-4 tab:mb-6 font-bold text-2xl tab:text-4xl desk:text-6xl">Contact Us</h1>
        <p className="mb-8 tab:mb-8 font-semibold text-xl tab:text-2xl desk:text-4xl">
          We’re Here to Listen and Help!
        </p>
        <p className="mx-auto text-xs desk:text-2xl tab:text-base">
          At MutaEngine, we value your feedback, offer dedicated support, and are open to exploring new business opportunities. Please choose the relevant form below to get in touch with us.
        </p>
      </div>

      {/* Tab Buttons */}
      <div className="relative flex justify-around mx-auto mb-16 w-11/12 tab:w-3/4 text-xs tab:text-lg">
        <Button 
          title="Feedback" 
          variant={activeTab === 'feedback' ? 'contained' : 'outlined'} 
          onClick={() => setActiveTab('feedback')}
          className="px-[15px] tab:px-6"
        />
        <Button 
          title="Support Hub" 
          variant={activeTab === 'support' ? 'contained' : 'outlined'} 
          onClick={() => setActiveTab('support')}
          className="px-[15px] tab:px-6"
        />
        <Button 
          title="Business Inquiry" 
          variant={activeTab === 'business' ? 'contained' : 'outlined'} 
          onClick={() => setActiveTab('business')}
          className="px-[15px] tab:px-6"
        />
      </div>

      {/* Rendering the form based on the active tab */}
      <div className="relative">
        {renderForm()}
        <div className="bottom-[-250px] left-[-140px] z-0 absolute bg-purple-500 opacity-60 blur-[150px] w-[250px] h-[250px]"></div>
        <div className="bottom-[-250px] left-[-140px] z-0 absolute bg-purple-500 opacity-60 blur-[150px] w-[250px] h-[250px]"></div>
      </div>

      {/* Additional background effects */}
    </>
  );
};

export default Contact;
