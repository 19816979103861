import React from 'react';
import Getin from '../component/About/Getin';
import TwoColSection from '../component/CommonSection/TwoColSection';
import Pricing from '../component/ServicePage/Pricing';
import LapImg from '../images/ServicePage/Dall.png';
import Product from '../images/ServicePage/product.png';

const ServicePage = () => {
  return (
    <div>
      <div className="relative mx-auto container">
        <div className="top-1/4 left-[-190px] absolute bg-white opacity-60 blur-[150px] w-[250px] h-[250px]"></div>
        <TwoColSection
          Image={LapImg}
          leftHeading={'Products and Services'}
          colorHeading={''}
          rightHeading={''}
          des={
            'MutaEngine offers a suite of advanced tools designed to enhance the security and integrity of obfuscated files for organizations of all sizes. MutaIntegri provides robust integrity verification, ensuring that any tampering or unauthorized modifications are detected instantly, while MutaCryptor Lite offers scalable encryption and decryption solutions tailored for small to medium-sized enterprises, enabling secure data management without complex infrastructure. For large enterprises, MutaCryptor Pro delivers high-performance, multi-node processing capabilities to handle vast datasets with ease, providing comprehensive protection and efficient file handling. Together, these solutions empower businesses to maintain high security standards, protect sensitive data, and ensure ongoing compliance with industry regulations.'
          }
          button={''}
          type={0}
          textAlgin={0}
        />
      </div>
      <div className='flex flex-col gap-20 mx-auto container'>
        <TwoColSection
          heading={'MutaIntegri'}
          Image={Product}
          leftHeading={'What is the'}
          colorHeading={'MutaIntegri'}
          rightHeading={'?'}
          des={
            'MutaIntegri is a robust integrity verification tool designed to ensure the ongoing security and integrity of obfuscated files. This solution is critical for organizations needing to maintain high security standards for their software, ensuring that any tampering or unauthorized modifications are promptly detected.'
          }
          button={'Learn More'}
          conicGradient={true}
          redirect={'MutaIntegri'}
          type={1}
          textAlgin={1}
          wid={62}
          mdWid={52}
        />
        <div className="relative">
          <div className="top-1/4 left-[-190px] absolute bg-white opacity-60 blur-[150px] w-[250px] h-[250px]"></div>
          <TwoColSection
            heading={'MutaCryptor Lite'}
            Image={Product}
            leftHeading={'What is the'}
            colorHeading={'MutaCryptor Lite'}
            rightHeading={'?'}
            des={
              'MutaCryptor Lite is an efficient tool for the encryption, decryption, and management of obfuscated files. It supports unlimited files, making it ideal for small to medium-sized enterprises looking to enhance their software security without the need for complex infrastructure.'
            }
            button={'Learn More'}
            conicGradient={true}
            redirect={'MutaCryptorLite'}
            type={0}
            textAlgin={1}
            wid={62}
            mdWid={52}
          />
        </div>
        <TwoColSection
          heading={'MutaCryptor Pro'}
          Image={Product}
          leftHeading={'What is the'}
          colorHeading={'MutaCryptor Pro'}
          rightHeading={'?'}
          des={
            'MutaCryptor Pro is our premium solution, designed for large enterprises that require robust, scalable, and high-performance encryption and decryption capabilities. It supports multi-node processing, ensuring that even the largest and most complex datasets can be managed efficiently and securely.'
          }
          button={'Learn More'}
          conicGradient={true}
          redirect={'MutaCryptorPro'}
          type={1}
          textAlgin={1}
          wid={62}
          mdWid={52}
        />
        <div className="relative">
          <div className="right-[-190px] bottom-1 absolute bg-blue-500 opacity-60 blur-[150px] w-[250px] h-[250px]"></div>
          <Pricing />
        </div>

        <Getin />
      </div>
    </div>
  );
};

export default ServicePage;
