import React from "react";
import { FaCheck } from "react-icons/fa";
import Icon from "../../images/MutaIntegri/Icon.png";
import Arrow from "../../images/MutaIntegri/Arrow.png";
import { useNavigate } from "react-router-dom";
const Details = (props) =>{
      const navigate = useNavigate();
      return (
        <div className="flex flex-col">
          
        <div className="flex flex-col md:flex-row justify-center items-stretch gap-12 p-6">
          
          {props.plans.map((plan) => (
            <div
              key={plan.id}
              className={`w-full md:w-80 flex flex-col p-6 ${
                plan.highlighted
                  ? "bg-[#1716A8] text-white"
                  : "bg-white text-black-900"
              } shadow-lg rounded-lg transition-transform duration-300 `}
            >
              <div className="p-4 border-b border-gray-200">
                <h3 className="flex items-center gap-[4px] text-2xl font-bold " style={plan.highlighted ? { color: "" } : { color: "#0B0914" }}>{plan.highlighted ? (<></>) : (<img src={Icon} width="12%"alt="" ></img>)}{plan.name}</h3>
              </div>
              <div className="flex-grow p-4 flex flex-col justify-between">
                <div>
                  {plan.highlighted ? "" : <p className="text-sm mb-4 Montserrat" style={plan.highlighted ? { color: "" } : { color: "#A9A9AA" }}>What You'll Get</p> }
                  
                  <ul className="space-y-2">
                    {plan.features.map((feature, featureIndex) => (
                      <li key={featureIndex} className="flex items-start">
                        {plan.highlighted ? "" : <FaCheck className="mr-2 mt-[3px] h-4 w-4 bg-white p-1 rounded-full flex-shrink-0" style={plan.highlighted ? { fill: "#1716A8" } : { background: "#35353F" }}/> }
                  
                        <span className="text-sm Montserrat" style={plan.highlighted ? { color: "" } : { color: "#35353F" }}>{feature}</span>
                      </li>
                      
                    ))}
                  </ul>
                  {plan.highlighted ?<img className="mt-[5px]" src={Arrow} alt=""></img>: ""}
                </div>
              </div>
              {plan.highlighted ? <div className="w-full flex items-center justify-center"><img className="w-[80%]" src={plan.img} alt=""></img></div> :
              <div className="flex flex-col items-start p-4 border-t border-gray-200">
                <p className="text-3xl font-bold mb-2" style={plan.highlighted ? { color: "" } : { color: "#35353F" }}>
                  {plan.price}
                  <span className="text-sm font-normal " style={plan.highlighted ? { color: "" } : { color: "#35353F" }}>/year</span>
                </p>
                <button
                  onClick={() => navigate('checkout')}
                  className={`w-full py-2 ${
                    plan.highlighted
                      ? "bg-white text-[#1716A8] hover:bg-gray-200"
                      : "bg-[#1716A8] text-white hover:bg-[#04036F]"
                  } rounded-lg Montserrat`}
                >
                  Choose
                </button>
              </div>}
            </div>
          ))}
        </div>
        </div>
      );
}

export default Details;