import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../CommonSection/Button";
const ReadyTo = () => {
    const navigate = useNavigate();
    return(
        <div className="flex justify-center items-center my-20 px-5">
      <div className="flex justify-between p-10 rounded-3xl readyto" style={{background: "linear-gradient(155deg, rgb(109, 109, 109) 10%, rgb(17, 16, 29) 100%)"}}>
        <div className="w-full sm:w-full lg:w-[50%] width100at1024" style={{background:"none"}}>
        <h2 className="mb-[2rem] font-bold text-5xl text-white" style={{background:"none"}}>
          Ready to <span className="text-cyan-400" style={{background:"none"}}>Secure</span> Your Software?
        </h2>
        <p className="mb-6 text-gray-300" style={{background:"none",fontSize:"1.3rem"}}>
          Get started with MutaEngine today and protect your software with the most advanced security technology available.
        </p>
        </div>
        <div className="flex tab:flex-[0.3] justify-center md:justify-start items-center" style={{background:"none"}}>
        <Button 
          title="Start Now" 
          type="button" 
          variant="outlined"
          fullWidth
          conicGradient={true}
          onClick={() => navigate('/login')} 
          className="py-3" />
        </div>
        
      </div>
    </div>
    );
}

export default ReadyTo;