import React from 'react';

const CountSection = () => {
  function StatItem({ number, label }) {
    return (
      <div className="flex items-center text-center">
        <span className="mr-[15px] mb-2 font-bold text-4xl">{number}</span>
        <span className="w-[150px] md:w-[50px] text-cyan-400 text-sm uppercase tracking-wider Montserrat">
          {label}
        </span>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center py-8 w-full text-white">
      <div className="flex md:flex-row flex-col flex-1 justify-between items-center gap-2 desk:gap-6 w-full md:max-xl:max-w-[80%] xl:max-w-[75%]">
        <StatItem number="5000+" label="ACTIVE USERS" />
        <div className="md:block hidden bg-white w-[1px] h-[14px]"></div>
        <StatItem number="300+" label="COMPANIES TRUSTING MUTAENGINE" />
        <div className="md:block hidden bg-white w-[1px] h-[14px]"></div>
        <StatItem number="7000+" label="UPDATED USERS" />
      </div>
    </div>
  );
};

export default CountSection;
