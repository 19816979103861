import classNames from 'classnames';

const Textarea = ({
  name,
  border,
  placeholder,
  value,
  onChange,
  rows = 4,
  className,
  fullWidth,
}) => {
  return (
    <div className="flex flex-col justify-center gap-2">
      <textarea
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        rows={rows}
        required
        className={classNames(
          `border-gray-700 outline-none tab:placeholder:text-base tab:text-base text-sm placeholder:text-sm bg-gray-900 p-3 border rounded-md focus:ring-2 text-4xs ${className}`,
          {
            'border-blue ': border === 'blue',
            'border-purple-600 ': border === 'purple',
            'w-full': fullWidth,
          }
        )}
      />
    </div>
  );
};

export default Textarea;