import sphere from '../../images/contactPage/sphere.png';
import knot from '../../images/contactPage/knot.png';
import circles from '../../images/contactPage/circles.png';

const SphereKnotBG = () => {
    return (
    <div>
        <img src={circles} alt="circles" className="hidden tab:block absolute top-0 left-0 w-[500px] h-[500px]" />
        <img src={sphere} alt="sphere" className="hidden tab:block absolute top-40 left-0 blur-md tab:w-[268px] tab:h-[268px] desk:w-[434px] desk:h-[434px]" />
        <img src={knot} alt="knot" className="absolute top-0 right-0 w-[190px] h-[259px] tab:w-[300px] tab:h-[400px] desk:w-[600px] desk:h-[600px] opacity-90" />
    </div>
    );   
}

export default SphereKnotBG;