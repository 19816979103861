import React from 'react';
import { RxCross2 } from 'react-icons/rx';
import { privacy } from '../../utils/data';

const PrivacyPolicyDialog = ({ open, close }) => {
  const { EffectiveDate, Sections } = privacy.PrivacyPolicy;

  return (
    <div
      className={`
        ${open ? 'flex' : 'hidden'}
        fixed inset-0 z-50 justify-center items-center bg-black bg-opacity-50 backdrop-blur-sm px-3 py-5 sm:p-9
      `}
    >
      {/* Privacy Policy Box */}
      <div className="relative flex flex-col items-center shadow-lg rounded-[20px] md:rounded-[40px] w-full md:max-w-[680px] h-fit overflow-hidden">
        {/* Content Box */}
        <div className="relative z-10 bg-[#0d0f1a] bg-opacity-95 px-8 border border-blue rounded-[20px] md:rounded-[40px] w-full text-white">
          <div className="flex justify-between items-center pt-8">
            <h2 className="mb-4 font-bold text-3xl sm:text-4xl leading-[150%]">
              Privacy Policy
            </h2>
            <RxCross2
              className="w-6 h-6 cursor-pointer"
              onClick={() => close()}
            />
          </div>

          {/* Display Effective Date */}
          <p className="mb-4 text-gray-400">Effective Date: {EffectiveDate}</p>

          {/* Map through sections of Privacy Policy */}
          <div className="flex flex-col custom-scrollbar mb-4 h-full max-h-[450px] text-left overflow-y-auto">
            {Sections.map((section, index) => (
              <div key={index} className="mb-6">
                <h3 className="font-semibold text-2xl sm:text-3xl">{section.Section}</h3>
                <div className="mt-2">
                  {/* If "Details" is an array of objects (like in Section 01) */}
                  {section.Details.map((detail, i) => (
                    <div key={i}>
                      {/* Handle Details with "Type" and "DataCollected" */}
                      {typeof detail === 'object' && (
                        <>
                          <h4 className="mt-4 font-semibold text-lg">
                            {detail.Type}
                          </h4>
                          <ul className="space-y-1 pl-5 list-disc">
                            {detail.DataCollected.map((item, i) => (
                              <li key={i}>{item}</li>
                            ))}
                          </ul>
                        </>
                      )}

                      {/* Handle Details if it's a string (like in other sections) */}
                      {typeof detail === 'string' && (
                        <p className="mt-2">{detail}</p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyDialog;
