import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../CommonSection/Button";
const Getin = () => {
    const navigate = useNavigate();
    return(
        <div className="flex justify-center items-center mx-auto mt-[6rem] mb-[6rem] container">
      <div className="flex justify-between p-10 rounded-3xl w-full readyto" style={{background: "linear-gradient(155deg, rgb(109, 109, 109) 10%, rgb(17, 16, 29) 100%)"}}>
        <div className="w-full sm:w-full lg:w-[50%] width100at1024" style={{background:"none"}}>
        <h2 className="mb-[2rem] font-bold text-5xl text-white" style={{background:"none"}}>
        Get In<span className="text-cyan-400" style={{background:"none"}}> Touch</span>
        </h2>
        <p className="mb-6 text-gray-300" style={{background:"none",fontSize:"1.3rem"}}>
        Have questions or want to learn more about how MutaEngine can protect your software? We’d love to hear from you. [Provide contact information, social media links, and a contact form.]
        </p>
        </div>
        <div className="flex justify-center md:justify-start items-center mr-[10%]" style={{background:"none"}}>
        <Button 
          title="Contact Us" 
          type="button" 
          variant="contained" 
          onClick={() => navigate('/contact')}
          className='py-3' 
        />

        </div>
        
      </div>
    </div>
    );
}

export default Getin;