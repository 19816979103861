import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Textarea from "../CommonSection/Textarea";
import TermsDialog from "../CommonSection/TermsDialog";
import PrivacyPolicyDialog from "../CommonSection/PrivacyDialog";

const BillingSummary = () => {
  const location = useLocation();
  const fullPath = location.pathname.split('/');
  const productType = fullPath[1];
  // Default billing values
  const [subtotal, setSubtotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [shipping, setShipping] = useState(0);
  const [tax, setTax] = useState(0);

  // Calculate grand total
  const grandTotal = subtotal - discount + shipping + tax;
  
  const [comment, setComment] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isTermsDialogOpen, setIsTermsDialogOpen] = useState(false);
  const [isPrivacyDialogOpen, setIsPrivacyDialogOpen] = useState(false);
  const handleCommentChange = (e) => setComment(e.target.value);
  const handleTermsChange = () => setTermsAccepted(!termsAccepted);
  const handleTermsClick = () => setIsTermsDialogOpen(true);
  const handlePrivacyClick = () => setIsPrivacyDialogOpen(true);

  // Update billing values based on product type
  useEffect(() => {
    switch (productType) {
      case 'MutaIntegri':
        setSubtotal(999.00);
        setTax(0.18*999);
        break;
      case 'MutaCryptorLite':
        setSubtotal(1499.00);
        setTax(0.18*1499);
        break;
      case 'MutaCryptorPro':
        setSubtotal(2499.00);
        setTax(0.18*2499);
        break;
      default:
        break;
    }
  }, [productType]);

  return (
    <>
      <h2 className="text-xl tab:text-2xl desk:text-3xl font-semibold pb-4 mb-6 border-b-2 border-dashed border-gray-300">
        Billing Summary
      </h2>
      <div className="border-b border-gray-700 pb-4 space-y-2">
        <p className="flex justify-between text-sm tab:text-sm">
          <span>Subtotal</span> <span>₹{subtotal.toFixed(2)}</span>
        </p>
        <p className="flex justify-between text-sm tab:text-sm">
          <span>Discount</span> <span>-₹{discount.toFixed(2)}</span>
        </p>
        <p className="flex justify-between text-sm tab:text-sm">
          <span>Shipping</span> <span>₹{shipping.toFixed(2)}</span>
        </p>
        <p className="flex justify-between text-sm tab:text-sm">
          <span>Tax</span> <span>₹{tax.toFixed(2)}</span>
        </p>
      </div>

      <p className="flex justify-between text-base desk:text-xl font-semibold mt-4">
        <span>Grand Total</span> <span>₹{grandTotal.toFixed(2)}</span>
      </p>

      <div className="mt-4">
        <Textarea
          name="comment"
          placeholder="Order Comment"
          value={comment}
          onChange={handleCommentChange}
          className="focus:border-purple-600 mt-2"
          rows={3}
        />
      </div>

      <div className="flex items-center mt-4">
        <input
          type="checkbox"
          id="terms"
          checked={termsAccepted}
          onChange={handleTermsChange}
          className="mr-2 checked:accent-indigo-700 checked:border-transparent w-5 h-5 rounded-md"
        />
        <label htmlFor="terms" className="text-sm tab:text-sm">
          Please check to acknowledge our{" "}
          <span className="text-blue-400 cursor-pointer text-indigo-500" onClick={handleTermsClick}>
            Terms & Conditions
          </span>
          {" "}and{" "}
          <span className="text-blue-400 cursor-pointer text-indigo-500" onClick={handlePrivacyClick}>
            Privacy Policy
          </span>
        </label>
      </div>

      {/* Render TermsDialog if it is open */}
      {isTermsDialogOpen && (
        <TermsDialog
          open={isTermsDialogOpen}
          close={() => setIsTermsDialogOpen(false)}
        />
      )}
      {/* Render PrivacyPolicyDialog if it is open */}
      {isPrivacyDialogOpen && (
        <PrivacyPolicyDialog
          open={isPrivacyDialogOpen}
          close={() => setIsPrivacyDialogOpen(false)}
        />
      )}
    </>
  );
}

export default BillingSummary;
