import classNames from 'classnames';
import React from 'react';

const Button = ({ title, loading, onClick, type, variant, fullWidth, className, conicGradient }) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={classNames(
        `px-6 py-2 font-montserrat rounded-2xl w-fit border-2 font-semibold border-blue shadow-buttonShadow ${className}`,
        {
          'text-white bg-black': variant === 'outlined',
          'text-black bg-blue': variant === 'contained',
          'w-full': fullWidth,
          'conic': conicGradient,
        }
      )}
    >
      {title}
    </button>
  );
};

export default Button;