import { useState } from "react";
import Input from "../CommonSection/Input";
import Textarea from "../CommonSection/Textarea";
import axiosInstance from "../../api/axiosInstance";

const FeedbackForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    setSuccessMessage(null);

    try {
      await axiosInstance.post(`${process.env.REACT_APP_API_URL}/feedback/`, {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        phone_number: formData.phoneNumber,
        message: formData.message
      });
      setSuccessMessage("Thank you for your feedback!");
      setFormData({ firstName: "", lastName: "", email: "", phoneNumber: "", message: "" });
    } catch (err) {
      setError("An error occurred while submitting the form. Please try again.");
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="relative z-30 flex justify-center items-center mx-auto mb-20 container">
      <form
        onSubmit={handleSubmit}
        className="border-[#763AF5] bg-[#00040F] mx-auto px-6 desk:px-20 tab:px-12 py-8 desk:py-16 tab:py-8 border rounded-3xl w-11/12 desk:w-9/12 tab:w-11/12 text-white"
      >
        <h2 className="mx-auto pb-6 desk:pb-12 tab:pb-8 desk:w-3/4 tab:w-4/5 font-Montserrat font-semibold text-center text-sm desk:text-xl tab:text-base">
          We’re always looking to improve. Share your thoughts, suggestions, or
          experiences with MutaEngine your feedback helps us serve you better!
        </h2>

        <div className="tab:gap-3 grid tab:grid-cols-2">
          <Input
            type="text"
            name="firstName"
            placeholder="First Name"
            value={formData.firstName}
            onChange={handleChange}
            className="focus:border-purple-600"
          />

          <Input
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={formData.lastName}
            onChange={handleChange}
            className="focus:border-purple-600"
          />
        </div>

        <Input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          className="focus:border-purple-600"
        />

        <Input
          type="text"
          name="phoneNumber"
          placeholder="Phone Number"
          value={formData.phoneNumber}
          onChange={handleChange}
          className="focus:border-purple-600"
        />

        <Textarea
          name="message"
          placeholder="Message"
          value={formData.message}
          onChange={handleChange}
          className="focus:border-purple-600 mt-2"
          rows={4}
        />

        <div className="mt-6">
          <button
            type="submit"
            disabled={isSubmitting}
            className="bg-gradient-to-r from-[#763AF5] hover:from-[#A604F2] to-[#A604F2] hover:to-[#763AF5] py-3 rounded-md w-full font-semibold text-lg text-white tab:text-xl"
          >
            {isSubmitting ? "Sending..." : "Send"}
          </button>
        </div>

        {error && <p className="text-red-500 mt-4">{error}</p>}
        {successMessage && <p className="text-green-500 mt-4">{successMessage}</p>}
      </form>
    </div>
  );
};

export default FeedbackForm;
