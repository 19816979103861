import SphereKnotBG from '../component/CommonSection/SphereKnotBG';
import { terms } from '../utils/data';

const TermsOfUsePage = () => {
  return (
    <>
      <SphereKnotBG />
      <div className="tab:top-1/3 left-[-500px] absolute bg-purple-300 tab:opacity-15 blur-[40px] rounded-full tab:w-[800px] tab:h-[1000px]"></div>
      <div className="relative z-20 flex flex-col items-center mx-auto px-3 desk:px-10 tab:px-8 py-10 w-full min-h-screen container">
        {/* Terms of Use Content */}
        <div className="w-[90%] desk:w-[70%] tab:w-[80%]">
          <h2 className="mb-16 font-bold text-4xl text-center mob:text-5xl tab:text-7xl">
            Terms of Use
          </h2>

          {/* Map through terms and conditions */}
          <div className="flex flex-col text-left">
            {terms.map((term, index) => (
              <div key={index} className="mb-8">
                <h3 className="font-semibold text-2xl mob:text-3xl tab:text-4xl">
                  {term.section}
                </h3>

                <div className="my-8 pl-4">
                  {/* Render term content */}
                  <p className="mb-4 text-sm mob:text-lg tab:text-xl">
                    {term.content}
                  </p>

                  {/* Render list if it exists */}
                  {term.list && (
                    <ul className="space-y-2 py-4 pl-5 list-disc">
                      {term.list.map((listItem, listIndex) => (
                        <li key={listIndex} className="text-sm mob:text-lg tab:text-xl">
                          {listItem}
                        </li>
                      ))}
                    </ul>
                  )}

                  {/* Render note if it exists */}
                  {term.note && (
                    <p className="mt-4 text-sm mob:text-lg tab:text-xl">
                      {term.note}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      <div className="right-[-270px] tab:bottom-[-150px] z-0 absolute bg-indigo-300 tab:opacity-15 blur-[40px] rounded-full tab:w-[400px] tab:h-[400px]"></div>
      </div>
    </>
  );
};

export default TermsOfUsePage;
