import { useState } from "react";
import BillingAddress from "../component/Checkout/BillingAddress";
import BillingSummary from "../component/Checkout/BillingSummary";
import PaymentFailed from "../component/Checkout/PaymentFailed";
import PaymentMethod from "../component/Checkout/PaymentMethod";
import PaymentSuccess from "../component/Checkout/PaymentSuccess";
import SphereKnotBG from "../component/CommonSection/SphereKnotBG";

const Checkout = () => {
  const [isProceedToPay, setIsProceedToPay] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);

  const handlePayment = (status) => {
    setPaymentStatus(status);
  };

  return (
    <div className="mx-auto container" >
      {paymentStatus ==='success' ? (
        <PaymentSuccess />
      ) : paymentStatus === 'failed' ? (
        <PaymentFailed />
      ) : (
        <>
          <SphereKnotBG />
          <div className="tab:block top-0 left-[200px] absolute hidden bg-purple-500 opacity-20 blur-[150px] w-[300px] h-[300px]"></div>
          <div className="top-40 tab:top-1/3 left-[-200px] absolute bg-purple-300 tab:opacity-20 blur-[40px] w-[50px] tab:w-[500px] h-[50px] tab:h-[500px]"></div>

          <div className="relative z-20 mx-auto mb-12 desk:mb-32 tab:mb-20 pt-5 desk:pt-20 tab:pt-10 w-11/12 desk:w-2/3 tab:w-3/4 text-center text-white">
            <h1 className="font-bold text-6xl tab:text-7.5xl">Checkout</h1>
          </div>
          <div className="relative z-20 flex tab:flex-row flex-col justify-center items-center mx-auto pb-20 w-full desk:w-10/12 tab:w-11/12 text-white">
            {/* Billing Summary Section */}    
            <div className="border-[#763AF5] tab:order-2 bg-[#00040F] mx-auto mb-12 tab:mb-0 desk:ml-8 px-8 desk:px-8 tab:px-7 py-12 border rounded-3xl w-3/4 tab:w-2/5 desk:1/3">
              <BillingSummary />
            </div>

            {/* Conditional Rendering based on paymentStatus */}
            <div className="border-[#763AF5] tab:order-1 bg-[#00040F] mx-auto tab:mr-8 p-8 desk:p-16 tab:p-9 border rounded-3xl w-4/5 desk:w-2/3 tab:w-3/5 text-white">
              {!isProceedToPay ? (
                <BillingAddress setIsProceedToPay={setIsProceedToPay} />
              ) : (
                <PaymentMethod onPayment={handlePayment} />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Checkout;
