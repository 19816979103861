import React, { useEffect, useState } from 'react';
import { IoLogoGoogle, IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { Link } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance';
import Button from '../component/CommonSection/Button';
import Input from '../component/CommonSection/Input';
import PrivacyPolicyDialog from '../component/CommonSection/PrivacyDialog';
import TermsDialog from '../component/CommonSection/TermsDialog';

const SignupPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isTermsDialogOpen, setIsTermsDialogOpen] = useState(false);
  const [isPrivacyDialogOpen, setIsPrivacyDialogOpen] = useState(false);

  const handleGoogleSuccess = async (credentialResponse) => {
    console.log(credentialResponse);
    console.log('Hello World!');

    // Use the credentialResponse to make a backend call
    try {
      const response = await axiosInstance.post('/google-complete/', {
        token: credentialResponse.credential,
      });

      console.log(response.data);
      // signInUser(response.data); // Ensure signInUser is defined elsewhere in your code
    } catch (error) {
      console.error('Google sign-in error:', error);
    }
  };

  const handleGoogleFailure = () => {
    console.log('Google Sign-In was unsuccessful. Try again later.');
  };

  const handleGoogleClick = () => {
    const auth2 = window.gapi.auth2.getAuthInstance();
    if (auth2) {
      auth2
        .signIn()
        .then(handleGoogleSuccess)
        .catch(handleGoogleFailure);
    } else {
      console.error('Google auth2 instance is not initialized.');
    }
  };  

  useEffect(() => {
    // Initialize the Google API
    const initGoogleAuth = () => {
      window.gapi.load('auth2', () => {
        if (window.gapi.auth2) {
          window.gapi.auth2
            .init({
              client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            })
            .then(() => {
              console.log('Google API initialized successfully');
            })
            .catch((error) => {
              console.error('Error initializing Google API:', error);
            });
        } else {
          console.error('Google API auth2 failed to load.');
        }
      });
    };    

    initGoogleAuth();
  }, []);

  return (
    <>
      <div className="top-0 left-0 absolute bg-backdrop bg-cover bg-no-repeat bg-center blur-md w-full h-full"></div>
      <div className="pb-5">
        {/* Main Signup Page */}
        <div className="flex justify-center items-center mx-auto px-3 py-5 sm:p-9 min-h-screen container">
          <div className="relative flex flex-col items-center rounded-[40px] w-full md:max-w-[680px] h-fit overflow-hidden">
            <div className="z-0 absolute bg-[#03060e] bg-opacity-80 backdrop-blur-sm w-full h-full"></div>
            <div className="relative z-10 px-5 desk:px-20 py-8 tab:py-12">
              <div className="text-center">
                <h2 className="font-bold text-2xl desk:text-[42px] leading-[110%]">
                  Sign Up Free
                </h2>
              </div>
              <form className="mt-8 w-full font-montserrat">
                <div className="flex flex-col gap-4">
                  <div className="gap-2.5 grid grid-cols-2">
                    <Input
                      label={'First Name'}
                      type="text"
                      className={'focus:border-blue '}
                      placeholder={'First'}
                    />
                    <Input
                      label={'Last Name'}
                      type="text"
                      className={'focus:border-blue '}
                      placeholder={'Last'}
                    />
                  </div>
                  <Input
                    label={'Enter Email'}
                    type="email"
                    className={'focus:border-blue '}
                    placeholder={'Email'}
                  />
                  <div className="relative">
                    <Input
                      label={'Password'}
                      placeholder={'Enter Password'}
                      fullWidth
                      className={'focus:border-blue !pr-8'}
                      type={showPassword ? 'password' : 'text'}
                    />
                    <span
                      onClick={() => setShowPassword(!showPassword)}
                      className="right-2 bottom-[14px] absolute cursor-pointer"
                    >
                      {!showPassword ? (
                        <IoMdEye className="w-[18px] h-[18px]" />
                      ) : (
                        <IoMdEyeOff className="w-[18px] h-[18px]" />
                      )}
                    </span>
                  </div>
                </div>
                <span className="mt-1 font-montserrat text-greyText text-xs leading-[140%]">
                  It must be a combination of minimum 8 letters, numbers, and
                  symbols. 
                </span>

                <div className="flex justify-center items-center gap-2 w-full">
                  <Input type="checkbox" />
                  <span className="mt-2 text-sm">Remember me</span>
                </div>
                <Button
                  title={'Sign Up'}
                  variant={'contained'}
                  fullWidth
                  className={'mt-4'}
                />
              </form>

              <div className="mt-5 w-full font-montserrat text-center">
                <p className="text-greyText leading-[140%]">Or log in with :</p>
                <button
                  onClick={handleGoogleClick}
                  className="my-4 py-3 rounded-md w-full font-semibold text-lg text-white primaryButtonBlue"
                >
                  <div className="flex justify-center items-center gap-2">
                    <IoLogoGoogle />
                    <p>Google</p>
                  </div>
                </button>
                {/* <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  onSuccess={handleGoogleSuccess}
                  onFailure={handleGoogleFailure}
                  useOneTap // This enables one-tap sign-in
                  buttonText="Sign in with Google"
                  className="hidden"
                  // cookiePolicy={'single_host_origin'}
                  ref={googleLoginRef}
                /> */}
              </div>

              <div className="flex flex-col items-center gap-3">
                <div className="flex justify-center items-center cursor-pointer">
                  <Link
                    to="/login"
                    className="mt-5 w-full text-center text-greyText text-sm tab:text-base leading-[140%]"
                  >
                    Already have an account? <b className="text-blue">Log in</b>
                  </Link>
                </div>
                <div className="text-center">
                  <p className="text-greyText text-sm tab:text-base leading-[140%] cursor-pointer">
                    Read{' '}
                    <span onClick={() => setIsTermsDialogOpen(true)}>
                      <b className="text-blue">Terms & Conditions</b>
                    </span>{' '}
                    And{' '}
                    <span onClick={() => setIsPrivacyDialogOpen(true)}>
                      <b className="text-blue">Privacy Policies</b>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Render TermsDialog if it is open */}
        {isTermsDialogOpen && (
          <TermsDialog
            open={isTermsDialogOpen}
            close={() => setIsTermsDialogOpen(false)}
          />
        )}
        {/* Render PrivacyPolicy Dialog if it is open */}
        {isPrivacyDialogOpen && (
          <PrivacyPolicyDialog
            open={isPrivacyDialogOpen}
            close={() => setIsPrivacyDialogOpen(false)}
          />
        )}
      </div>
    </>
  );
};

export default SignupPage;