import React, { useState } from "react";
import Button from "../CommonSection/Button"; 
import Input from "../CommonSection/Input";

const EditModal = ({ isOpen, section, userData, onClose, onSave }) => {
    const [formData, setFormData] = useState(userData);
    const [fieldError, setFieldError] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({...formData, [name]: value, address: {...formData.address, [name]: value}});
    };

    const onCancel = () => {
        setFormData(userData);
        setFieldError(null);
        onClose();
    };

    const handleSave = () => {
        if(!formData.firstName || !formData.lastName || !formData.phone || !formData.address.country || !formData.address.state || !formData.address.city || !formData.address.postalCode) {
            setFieldError("Please fill all the fields");
            return;
        }
        setFieldError(null);
        onSave(formData);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 px-4">
            <div className="bg-[#00040F] border border-purple-500 p-6 rounded-2xl w-full max-w-lg">
                
                {section === "personalInfo" && (
                    <div className="flex flex-col gap-4">
                        <h3 className="text-white text-2xl font-bold mb-4">Edit Personal Information</h3>
                        <Input
                            label="Email"
                            name="email"
                            value={formData.email}
                            fullWidth
                            readOnly
                        />
                        <Input
                            label="First Name"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            fullWidth
                        />
                        <Input
                            label="Last Name"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            fullWidth
                        />
                        <Input
                            label="Phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            fullWidth
                        />
                        {fieldError && <p className="text-red-500 text-sm">{fieldError}</p>}
                    </div>
                )}

                {section === "address" && (
                    <div className="flex flex-col gap-4">
                    <h3 className="text-white text-2xl font-bold mb-4">Edit Address</h3>
                        <Input
                            label="Country"
                            name="country"
                            value={formData.address.country}
                            onChange={handleChange}
                            fullWidth
                        />
                        <Input
                            label="State"
                            name="state"
                            value={formData.address.state}
                            onChange={handleChange}
                            fullWidth
                        />
                        <Input
                            label="City"
                            name="city"
                            value={formData.address.city}
                            onChange={handleChange}
                            fullWidth
                        />
                        <Input
                            label="Postal Code"
                            name="postalCode"
                            value={formData.address.postalCode}
                            onChange={handleChange}
                            fullWidth
                        />
                        {fieldError && <p className="text-red-500 text-sm">{fieldError}</p>}
                    </div>
                )}

                <div className="flex justify-end space-x-3 mt-6">
                    <Button title="Cancel" variant="outlined" onClick={onCancel} />
                    <Button title="Save" variant="outlined" onClick={handleSave} />
                </div>
            </div>
        </div>
    );
};

export default EditModal;
