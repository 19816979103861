export const navLinks = [
  {label:'Overview', href:'/'},
  {label:'About', href:'/about'},
  {label:'Services', href:'/service', subLink: [
    {label: 'MutaIntegri', href:'/MutaIntegri'},
    {label: 'MutaCryptorLite', href:'/MutaCryptorLite'},
    {label: 'MutaCryptorPro', href:'/MutaCryptorPro'},
  ]},
  {label:'Contact', href:'/contact'},
]

export const terms =[
  {
    section: "01. Introduction",
    content: "Welcome to MutaEngine! These Terms of Use govern your access to and use of MutaEngine's services, solutions, and websites. By using MutaEngine's products and services, you agree to comply with these terms. Please read them carefully."
  },
  {
    section: "02. Acceptance of Terms",
    content: "By accessing or using MutaEngine's website or services, you accept and agree to be bound by these Terms of Use and any applicable laws and regulations. If you do not agree with these terms, you are prohibited from using or accessing our services."
  },
  {
    section: "03. Modifications to Terms",
    content: "MutaEngine reserves the right to change, modify, or update these Terms of Use at any time. Any changes will be posted on this page, and your continued use of our services after any such changes signifies your acceptance of the modified terms."
  },
  {
    section: "04. Services Provided",
    content: "MutaEngine offers advanced software protection through solutions such as:",
    list: [
      "Polymorphic Code Engine: Continually mutates code at runtime to prevent reverse engineering, piracy, and unauthorized modifications.",
      "MutaIntegri: Provides robust integrity verification to detect tampering or unauthorized changes.",
      "MutaCryptor Lite: Scalable encryption solutions for small to medium-sized enterprises.",
      "MutaCryptor Pro: High-performance, multi-node encryption for large enterprises, handling vast datasets with ease."
    ],
    note: "These services are provided under licensing agreements, and you may use them in compliance with the licensing terms and conditions."
  },
  {
    section: "05. User Responsibilities",
    content: "As a user of MutaEngine services, you agree to:",
    list: [
      "Use our services and solutions for lawful purposes only.",
      "Comply with all applicable local, national, and international laws and regulations.",
      "Not engage in any activity that may harm, disrupt, or otherwise interfere with MutaEngine's services or infrastructure.",
      "Not reverse-engineer, de-compile, or attempt to access our source code or bypass security measures."
    ]
  },
  {
    section: "06. Intellectual Property",
    content: "All content, technology, and services provided by MutaEngine, including but not limited to the Polymorphic Code Engine, MutaIntegri, MutaCryptor Lite, and MutaCryptor Pro, are the intellectual property of MutaEngine or its licensors. You agree not to reproduce, copy, or otherwise misuse any part of our intellectual property without express written consent."
  },
  {
    section: "07. Disclaimer of Warranties",
    content: "MutaEngine provides its services on an 'as-is' and 'as-available' basis. We do not guarantee that our services will be uninterrupted, error-free, or completely secure. To the fullest extent permitted by law, MutaEngine disclaims all warranties, whether express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement."
  },
  {
    section: "08. Limitation of Liability",
    content: "MutaEngine will not be liable for any direct, indirect, incidental, consequential, or punitive damages, including but not limited to lost profits, data loss, or business interruption, arising from your use of or inability to use our services. This limitation applies to all claims, whether based on warranty, contract, tort, or any other legal theory."
  },
  {
    section: "09. Termination of Use",
    content: "MutaEngine reserves the right to terminate or suspend your access to our services at any time, with or without cause or notice. Upon termination, your right to use MutaEngine services will cease immediately."
  }
]

export const  privacy = {
  PrivacyPolicy: {
    EffectiveDate: "October 2024",
    Sections: [
      {
        Section: "01. Information We Collect",
        Details: [
          {
            Type: "Personal Information",
            DataCollected: [
              "Contact Information: Name, email address, phone number, and company name.",
              "Account Information: Username, password, and preferences or settings related to the account.",
              "Billing Information: Credit card or payment details for transactions."
            ]
          },
          {
            Type: "Technical Information",
            DataCollected: [
              "Device Information: Type of device, operating system, and browser type.",
              "Usage Data: IP address, time spent on website, pages visited, and links clicked.",
              "Cookies: Information stored in browser cookies to enhance experience."
            ]
          },
          {
            Type: "Third-Party Information",
            DataCollected: [
              "Data from third-party providers that help improve services, such as analytics platforms or payment processors."
            ]
          }
        ]
      },
      {
        Section: "02. How We Use Your Information",
        Details: [
          "To Provide Services: To deliver products like Polymorphic Code Engine, MutaIntengi, and MutaCryptor.",
          "Account Management: To manage and personalize account settings.",
          "Communication: To send updates, newsletters, and support responses.",
          "Payment Processing: For transactions of purchased services.",
          "Security and Fraud Prevention: To detect and prevent unauthorized access.",
          "Analytics and Improvement: To analyze usage and improve offerings."
        ]
      },
      {
        Section: "03. Sharing of Information",
        Details: [
          "Service Providers: Sharing with third-party vendors for operating services like payment processors or hosting.",
          "Legal Compliance: Disclosing data if required by law.",
          "Business Transactions: Sharing information in case of mergers, acquisitions, or asset sales."
        ]
      },
      {
        Section: "04. Data Security",
        Details: [
          "Industry-standard measures to protect data from unauthorized access, disclosure, alteration, or destruction.",
          "Use of encryption, access control, and regular security audits.",
          "Note: Transmission over the Internet cannot guarantee absolute security."
        ]
      },
      {
        Section: "05. Data Retention",
        Details: [
          "Retaining personal data only as long as necessary or as required by law.",
          "Deleting or anonymizing data when no longer needed."
        ]
      },
      {
        Section: "06. Your Rights and Choices",
        Details: [
          "Access Your Data: Request a copy of personal data held.",
          "Correct Your Data: Ask for updates or corrections.",
          "Delete Your Data: Request deletion subject to legal obligations.",
          "Opt-Out of Marketing: Unsubscribe from promotional communications."
        ]
      },
      {
        Section: "07. Cookies and Tracking Technologies",
        Details: [
          "Cookies used to collect browsing activity.",
          "Cookies help to personalize experience, analyze performance, and secure account access.",
          "Users can control cookie preferences through browser settings."
        ]
      },
      {
        Section: "08. Changes to This Privacy Policy",
        Details: [
          "MutaEngine reserves the right to update the policy at any time.",
          "Material changes will be posted with an effective date.",
          "Continued use of services implies acceptance of the revised policy."
        ]
      }
    ]
  }
}
