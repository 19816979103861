import React from "react";
import TwoColSection from "../component/CommonSection/TwoColSection";
import Benefit from "../component/Solutions/Benefit";
import Details from "../component/Solutions/Details";
import Features from "../component/Solutions/Features";
import Getin from "../component/Solutions/Getin";
import img1 from "../images/MutaCryptorPro/Benefits1.png";
import img2 from "../images/MutaCryptorPro/Benefits2.png";
import img3 from "../images/MutaCryptorPro/Benefits3.png";
import Features1 from "../images/MutaCryptorPro/Features1.png";
import Features2 from "../images/MutaCryptorPro/Features2.png";
import Features3 from "../images/MutaCryptorPro/Features3.png";
import Features4 from "../images/MutaCryptorPro/Features4.png";
import Helix from "../images/MutaCryptorPro/helix.png";
import Product from "../images/MutaCryptorPro/Without Shadow/MUTACRYPTOR-PRO-temp.webp";

const MutaCryptorPro = () => {

    const Benefits = [
        {
            img: img1,
            description:
                "Meets the rigorous demands of large enterprises with robust, scalable, and high-performance security solutions.",
        },
        {
            img: img2,
            description:
                'Ensures data integrity and confidentiality across all levels of the organization, from internal communication to customer data.',
        },
        {
            img: img3,
            description:
                'Optimizes processing speeds and enhances overall efficiency, reducing the time needed to secure large amounts of data.',
        },
    ];

    const features = [
        {
            icon: Features1,
            title: "Advanced Encryption and Decryption for Obfuscated Files",
            description:
                "Utilizes cutting-edge algorithms to protect your files, ensuring that they remain secure from unauthorized access or breaches.",
        },
        {
            icon: Features2,
            title: "Unlimited File Support for Large-Scale Operations",
            description:
                "Handles vast amounts of data without limitations, ideal for organizations with significant file management needs.",
        },
        {
            icon: Features3,
            title: "Multi-Node Processing",
            description:
                "Distributes encryption and decryption tasks across multiple nodes to enhance performance, speed, and scalability, crucial for handling large datasets efficiently.",
        },
        {
            icon: Features4,
            title: "Comprehensive Management Tools",
            description:
                "Offers an extensive suite of tools designed for enterprise-level file handling, providing deep insights and controls over your data security operations.",
        },
    ];

    const plans = [
        {
          id: 1,
          name: "MutaCryptor Pro",
          price: "$99",
          features: [
            "Ideal for: Large enterprises with extensive data management needs.",
            "Benefits: Handles large datasets efficiently, boosts security and performance, ensures compliance with stringent data protection standards",
          ],
          highlighted: true,
          img : Helix
        },
        {
          id: 2,
          name: "MutaCryptor Pro",
          price: "₹ 2499",
          features: [
            "Advanced encryption and decryption for obfuscated files",
            "Unlimited file support for large-scale operations",
            "Multi-node processing for high performance and speed",
            "Comprehensive enterprise-level management tools",
            "Priority customer support",
          ],
          highlighted: false,
        },
      ];


    return (
        <div >
            <div className="relative mx-auto container">

                <div className="top-1/4 left-[-190px] absolute bg-white opacity-60 blur-[150px] w-[250px] h-[250px]"></div>
                <TwoColSection Image={Product} leftHeading={"What is the"}
                    colorHeading={"MutaCryptor Pro"}
                    rightHeading={"?"}
                    des={"MutaCryptor Pro is our premium solution, designed for large enterprises that require robust, scalable, and high-performance encryption and decryption capabilities. It supports multi-node processing, ensuring that even the largest and most complex datasets can be managed efficiently and securely."}
                    // button={"Learn More"}
                    // conicGradient={true}
                    type={1}
                    textAlgin={1}
                    wid={62}
                    mdWid={52}
                />
            </div>
            <div className="pt-20 font-bold text-3xl text-center sm:text-4xl md:text-5xl">Key Features</div>
            <Features features={features} />
            <div className="font-bold text-3xl text-center sm:text-4xl md:text-5xl">Benefits</div>
            <Benefit features={Benefits} />
            <Details plans={plans}/>
            <Getin />
        </div>
    );
}

export default MutaCryptorPro;