// const [loading, setLoading] = useState(true);
// const [error, setError] = useState(null);

// useEffect(() => {
//     const fetchUserData = async () => {
//         try {
//             const response = await fetch("/api/user");
//             const data = await response.json();
//             setUserData(data);
//         } catch (err) {
//             setError("Failed to load user data");
//         } finally {
//             setLoading(false);
//         }
//     };

//     fetchUserData();
// }, []);

// if (loading) {
//     return <div className="text-white text-center">Loading...</div>;
// }

// if (error) {
//     return <div className="text-red-500 text-center">{error}</div>;
// }
import { useEffect, useState, useRef } from "react";
import { IoMdCamera, IoMdCreate } from "react-icons/io";
import Button from "../CommonSection/Button";
import EditModal from "./EditModal";

const ProfileCard = () => {
    const [userData, setUserData] = useState({
        firstName: "User",
        lastName: "Name",
        email: "usermail@gmail.com",
        phone: "1234567890",
        address: {
            country: "country",
            state: "state",
            city: "city",
            postalCode: "123456"
        }
    });

    const [imagePreview, setImagePreview] = useState("");
    const [showImageOptions, setShowImageOptions] = useState(false);
    const fileInputRef = useRef(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [sectionToEdit, setSectionToEdit] = useState("");

    // Handle image upload
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImagePreview(imageUrl);
        }
        setShowImageOptions(false);
    };

    // Trigger file input click
    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    // Handle image removal
    const handleRemoveImage = () => {
        setImagePreview("");
        setShowImageOptions(false);
    };

    // Handle clicking on camera icon (opens options)
    const handleCameraClick = () => {
        setShowImageOptions(true);
    };

    const handleCloseModal = (e) => {
        if (e.target === e.currentTarget) {
            setShowImageOptions(false); 
        }
    };

    const openEditModal = (section) => {
        setSectionToEdit(section);
        setEditModalOpen(true);
    };

    const closeEditModal = () => {
        setEditModalOpen(false);
    };

    const handleSave = (updatedData) => {
        setUserData(updatedData);
    };

    useEffect(() => {
        if (showImageOptions) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [showImageOptions]);

    return (
        <div className="text-white mx-5 relative">
            {/* Profile Picture and Welcome Text */}
            <div className="text-center mb-8 relative">
                {/* Profile Image */}
                <div className="relative w-32 h-32 mx-auto group">
                    <img
                        className="w-32 h-32 rounded-full border-4 border-white object-cover"
                        src={imagePreview || "https://via.placeholder.com/150"}
                        alt="User"
                    />

                    {/* Camera Icon: Shown on hover */}
                    <IoMdCamera
                        className="absolute inset-0 w-8 h-8 m-auto rounded-full cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                        onClick={handleCameraClick}
                    />
                </div>

                {/* Hidden File Input for Image Upload */}
                <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleImageChange}
                />

                <h2 className="text-2xl font-bold mt-4">Welcome, {userData.firstName}</h2>
            </div>

            {/* Modal with Image Options */}
            {showImageOptions && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
                onClick={handleCloseModal}>
                    <div className="flex flex-col bg-[#00040F] border border-purple-500 p-12 rounded-lg">
                        <h3 className="text-white text-lg font-bold mb-4">Choose an action</h3>
                        <Button
                            variant={"outlined"}
                            onClick={triggerFileInput}
                            title={"Choose Image"}
                            className={"mb-4"}
                            fullWidth={true}
                        />
                        <Button
                            variant={"outlined"}
                            onClick={handleRemoveImage}
                            title={"Remove Image"}
                            className={"mb-2"}
                            fullWidth={true}
                        />
                        <button
                            className="text-gray-600 mt-2 w-full"
                            onClick={() => setShowImageOptions(false)}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
            {/* Edit Modal for Name, Personal Info, Address */}
            <EditModal
                isOpen={editModalOpen}
                section={sectionToEdit}
                userData={userData}
                onClose={closeEditModal}
                onSave={handleSave}
            />
            {/* User Name Section */}
            <div className="bg-[#00040F] border border-[#763AF5] p-4 tab:px-6 mb-6 rounded-3xl relative">
                <div className="flex justify-between items-start tab:items-center">
                    <div className="flex flex-col items-center">
                        <div>
                            <h3 className="text-lg font-bold">
                                {userData.firstName} {userData.lastName}
                            </h3>
                            <p className="text-gray-400 break-all">{userData.email}</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Personal Information Section */}
            <div className="bg-[#00040F] border border-[#763AF5] p-4 tab:px-6 mb-6 rounded-3xl relative">
                <div className="flex justify-between items-start tab:items-center">
                    <h3 className="text-3xl font-bold">Personal Information</h3>
                    <Button
                        variant="outlined"
                        title={
                            <div className="flex items-center">
                                <IoMdCreate className="w-5 h-5 mr-2" /> Edit
                            </div>
                        }
                        className={"text-sm tab:text-base px-[15px] tab:px-6"}
                        onClick={() => openEditModal("personalInfo")}
                    />
                </div>
                <div className="grid grid-cols-2 gap-6 mt-4">
                    <div>
                        <p className="text-gray-400">First Name</p>
                        <p className="text-white">{userData.firstName}</p>
                    </div>
                    <div>
                        <p className="text-gray-400">Last Name</p>
                        <p className="text-white">{userData.lastName}</p>
                    </div>
                    <div>
                        <p className="text-gray-400">Email</p>
                        <p className="text-white break-all">{userData.email}</p>
                    </div>
                    <div>
                        <p className="text-gray-400">Phone</p>
                        <p className="text-white">{userData.phone || "Not Provided"}</p>
                    </div>
                </div>
            </div>

            {/* Address Section */}
            <div className="bg-[#00040F] border border-[#763AF5] p-4 tab:px-6 mb-6 rounded-3xl relative">
                <div className="flex justify-between items-center">
                    <h3 className="text-3xl font-bold">Address</h3>
                    <Button
                        variant="outlined"
                        title={
                            <div className="flex items-center">
                                <IoMdCreate className="w-5 h-5 mr-2" /> Edit
                            </div>
                        }
                        className={"text-sm tab:text-base px-[15px] tab:px-6"}
                        onClick={() => openEditModal("address")}
                    />
                </div>
                <div className="grid grid-cols-2 gap-4 mt-4">
                    <div>
                        <p className="text-gray-400">Country</p>
                        <p className="text-white">{userData.address.country}</p>
                    </div>
                    <div>
                        <p className="text-gray-400">State</p>
                        <p className="text-white">{userData.address.state}</p>
                    </div>
                    <div>
                        <p className="text-gray-400">City</p>
                        <p className="text-white">{userData.address.city}</p>
                    </div>
                    <div>
                        <p className="text-gray-400">Postal Code</p>
                        <p className="text-white">{userData.address.postalCode}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileCard;
