import React from 'react';
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Logo from '../images/logo.png';

const Footer = () => {
  return (
    <div className='bg-black relative z-40'>
    
    <footer className="relative bg-black px-5 min-[1700px]:px-20 text-gray-400 mx-auto container">
      <div className="flex md:flex-row flex-col justify-between items-center gap-5 px-4 py-6">
        <div className="flex items-center space-x-2">
          <img
            src={Logo}
            width={151}
            height={61}
            alt=""
            className="rounded-lg"
          />
        </div>

        <nav className="flex tab:flex-row flex-wrap justify-center items-center gap-5 md:gap-4 text-sm">
          <Link to="/" className='hover:text-cyan-500'>Overview</Link>
          <Link to="/about" className='hover:text-cyan-500'>About Us</Link>
          <Link to="/contact" className='hover:text-cyan-500'>Contact</Link>
          <Link to="/service" className='hover:text-cyan-500'>Features</Link>
          <Link to="/privacy-policy" className='hover:text-cyan-500'>Privacy Policy</Link>
          <Link to="/terms-of-use" className='hover:text-cyan-500'>Terms of Use</Link>
        </nav>
      </div>

      <div className="flex md:flex-row flex-col-reverse justify-between items-center gap-3 border-greyText px-4 py-5 border-t">
        <p className="py-4 text-center text-sm">
          MutaEngine © 2024. All rights reserved.
        </p>
        {/* Social Media Icons */}
        <div className="flex space-x-4 mt-4 md:mt-0">
          <a href="#youtube">
            <FaYoutube size={20} className='fill-gray-200 hover:fill-gray-400'  />
          </a>
          <a href="#facebook">
            <FaFacebookF size={20} className='fill-gray-200 hover:fill-gray-400'  />
          </a>
          <a href="#twitter">
            <FaTwitter size={20} className='fill-gray-200 hover:fill-gray-400'  />
          </a>
          <a href="#instagram">
            <FaInstagram size={20} className='fill-gray-200 hover:fill-gray-400'  />
          </a>
          <a href="#linkedin">
            <FaLinkedinIn size={20} className='fill-gray-200 hover:fill-gray-400'  />
          </a>
        </div>
      </div>
    </footer>
    </div>
  );
};

export default Footer;
