const Hero = () => {
  return (
    <div className="relative flex justify-between items-center bg-dark-blue bg-cover bg-no-repeat bg-center px-5 lg:px-24 pt-5 desk:pt-16 pb-8 desk:pb-32 text-white">
      <div className="relative z-10 flex flex-col items-center w-full text-center">
        <h1 className="mb-6 font-bold text-4xl desk:text-6xl">Our Mission</h1>
        <p className="mb-8 w-full desk:w-3/4 font-montserrat text-base lg:text-xl leading-[130%]">
          At MutaEngine, our mission is to revolutionize software security by
          providing cutting-edge solutions that protect your software from
          piracy, reverse engineering, and unauthorized modifications. We
          believe in empowering developers and companies to focus on innovation
          while we ensure their intellectual property remains secure.
        </p>
      </div>
    </div>
  );
};

export default Hero;
