import { useState } from 'react';
import Button from '../CommonSection/Button';
import Input from '../CommonSection/Input';

const BillingAddress = ({ setIsProceedToPay }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    streetAddress: '',
    state: '',
    city: '',
    zipCode: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleProceedToPay = () => {
    setIsProceedToPay(true);
  };

  return (
    <>
      <h2 className="border-greyText mb-4 pb-2 border-b-2 border-dashed font-semibold text-xl tab:text-2xl desk:text-3xl">
        Billing Address
      </h2>
      <div className="tab:gap-3 grid tab:grid-cols-2">
        <Input
          type="text"
          name="firstName"
          border={'purple'}
          placeholder="First Name"
          value={formData.firstName}
          onChange={handleChange}
        />
        <Input
          border={'purple'}
          type="text"
          name="lastName"
          placeholder="Last Name"
          value={formData.lastName}
          onChange={handleChange}
        />
      </div>

      <Input
        border={'purple'}
        type="email"
        name="email"
        placeholder="Email Address"
        value={formData.email}
        onChange={handleChange}
      />

      <Input
        type="text"
        name="streetAddress"
        border={'purple'}
        placeholder="Street Address"
        value={formData.streetAddress}
        onChange={handleChange}
      />

      <div className="tab:gap-3 grid tab:grid-cols-2">
        <Input
          type="text"
          border={'purple'}
          name="state"
          placeholder="State/Province"
          value={formData.state}
          onChange={handleChange}
        />
        <Input
          type="text"
          border={'purple'}
          name="city"
          placeholder="City"
          value={formData.city}
          onChange={handleChange}
        />
      </div>

      <div className="tab:gap-3 grid tab:grid-cols-2">
        <Input
          type="text"
          name="zipCode"
          placeholder="Zip/Postal Code"
          border={'purple'}
          value={formData.zipCode}
          onChange={handleChange}
        />
        <Input
          type="text"
          border={'purple'}
          name="phoneNumber"
          placeholder="Phone"
          value={formData.phoneNumber}
          onChange={handleChange}
        />
      </div>

      <div className="flex justify-center my-4">
        <input
          type="checkbox"
          id="sameAddress"
          className="mr-2 accent-blue-500 checked:accent-indigo-700 rounded-md w-4"
        />
        <label htmlFor="sameAddress" className="text-sm">
          My billing and shipping address are the same
        </label>
      </div>

      <div className="mt-6">
        <Button
          title="Proceed to Pay"
          type="button"
          variant="contained"
          fullWidth={true}
          className="text-sm tab:text-lg"
          onClick={handleProceedToPay}
        />
      </div>
    </>
  );
};

export default BillingAddress;
