import React from 'react';
import { useNavigate } from 'react-router-dom';
import Icon1 from '../../images/icons/icon1.png';
import Icon2 from '../../images/icons/icon2.png';
import Icon3 from '../../images/icons/icon3.png';
import Button from '../CommonSection/Button';
const YouInnovate = () => {
  const navigate = useNavigate();
  function FeatureCard({ icon, title, description }) {
    return (
      <div className="flex items-start gap-4">
        <div className="p-2 rounded-lg">
          <img
            src={icon}
            alt="icon"
            className="mx-auto w-full max-w-md"
            style={{ height: 64, width: 64 }}
          />
        </div>
        <div>
          <h3 className="mb-3 font-semibold text-lg xl:text-2xl">{title}</h3>
          <p className="text-gray-400 xl:text-lg Montserrat">{description}</p>
        </div>
      </div>
    );
  }
 
  return (
    <div className="relative my-20 px-5 text-center">
      <div className="right-[-190px] bottom-1 absolute bg-blue-500 opacity-60 blur-[150px] w-[250px] h-[250px]"></div>
      <div className="flex justify-center w-full h-full">
        <div className="flex justify-between w-full h-full text-left">
          <div className="flex-[0.5] space-y-6 youinnovate">
            <h1 className="font-bold md:text-5xl aboutHeadingFont mobiletextcenter">
              You Innovate,
              <br />
              We <span className="text-blue">Secure.</span>
            </h1>
            <p className="w-[570px] text-gray-400 text-lg mobiletextjustify Montserrat">
              With MutaEngine, you can focus on creating cutting-edge software
              while we ensure it stays protected from threats. Our advanced
              technology keeps your intellectual property safe, allowing you to
              concentrate on what you do best.
            </p>
 
            <Button
              type="button"
              title="Get Started"
              variant={'contained'}
              onClick={() => navigate('/service')}
            />
          </div>
          <div className="flex-[0.5] space-y-10">
            <FeatureCard
              icon={Icon1}
              title="Polymorphic Code"
              description="MutaEngine constantly mutates your software’s code in real-time, making it nearly impossible for hackers to decompile or reverse-engineer."
            />
            <FeatureCard
              icon={Icon2}
              title="360° Security"
              description="Our multi-layered defense mechanisms ensure that every part of your software is protected, from code to data to user transactions."
            />
            <FeatureCard
              icon={Icon3}
              title="Web3 Integration"
              description="Fully compatible with decentralized applications, MutaEngine provides robust security for your blockchain projects."
            />
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default YouInnovate;