import React from "react";
import Image from "../../images/MainPage/code.png";

const Code = () => {
    return (
        <div className="relative flex justify-center items-center w-full">
            <div className="bottom-1 left-[-190px] absolute bg-purple-500 opacity-60 blur-[150px] w-[250px] h-[250px]"></div>
            <img
                src={Image}
                
                alt=''
                className="rounded-lg w-full xl:w-[1600px] h-full xl:h-[900px]"
            />
        </div>
    );
}

export default Code;
