import React from "react";

const Benefit = (props) => {
    return (
        <div className="px-8 py-16">
            <div className="gap-8 grid grid-cols-1 md:grid-cols-3 mx-auto max-w-7xl">
                {props.features.map((feature, index) => (
                    <div key={index} className="flex flex-col justify-center items-center border-4 border-cyan-400 shadow-lg p-6 rounded-lg text-center" style={{ boxShadow: "10px 10px 9.7px 0px #4F95FF40"}}>
                        <p className="mx-[10%] Montserrat">{feature.description}</p>
                        <img
                            src={feature.img}
                            alt=''
                            className="mb-4 rounded-lg object-cover"
                        />
                        
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Benefit;