import classNames from 'classnames';
import React from 'react';

const Input = ({
  type = 'text',
  name,
  border,
  placeholder,
  label,
  value,
  onChange,
  fullWidth,
  className,
  readOnly = false,
}) => {
  return (
    <div className="flex flex-col justify-center gap-2">
      <label>{label}</label>
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required
        className={classNames(
          `border-gray-700 outline-none tab:placeholder:text-base tab:text-base text-sm placeholder:text-sm bg-gray-900 p-3 border rounded-md text-4xs ${className}`,
          {
            'border-blue ': border === 'blue',
            'focus:border-[#763AF5] ': border === 'purple',
            'w-full': fullWidth,
            'cursor-not-allowed opacity-80': readOnly,
          }
        )}
      />
    </div>
  );
};

export default Input;
