import { useState } from "react";
import { IoIosLock } from "react-icons/io";
import discoverIcon from "../../images/checkoutPage/discover.png";
import maestroIcon from "../../images/checkoutPage/maestro.png";
import mastercardIcon from "../../images/checkoutPage/mastercard.png";
import paypalIcon from "../../images/checkoutPage/paypal.png";
import visaIcon from "../../images/checkoutPage/visa.png";
import Button from "../CommonSection/Button";
import Input from "../CommonSection/Input";

const PaymentMethod = ({ onPayment }) => {
    const [paymentMethod, setPaymentMethod] = useState("credit-card");
    const [cardDetails, setCardDetails] = useState({ cardNumber: "", expirationDate: "", securityCode: "" });

    const handlePaymentChange = (e) => {
        setPaymentMethod(e.target.value);
    };

    const handleInputChange = (e) => {
        setCardDetails({ ...cardDetails, [e.target.name]: e.target.value });
    };

    const makePayment = () => {
        // payment logic
        const isPaymentSuccessful = Math.random() > 0.5;
        onPayment(isPaymentSuccessful ? 'success' : 'failed');
    };

    return (
        <>
            <h2 className="border-gray-300 mb-6 pb-4 border-b-2 border-dashed font-semibold text-white text-xl tab:text-2xl desk:text-3xl">
                Payment Method
            </h2>

            <div className="space-y-6 text-white">
                {/* PayPal Option */}
                <div className="flex justify-between tab:items-center border-gray-500 bg-gray-900 p-4 desk:p-6 border rounded-lg">
                    <div>
                        <label className="flex items-center">
                            <input
                                type="radio"
                                value="paypal"
                                checked={paymentMethod === "paypal"}
                                onChange={handlePaymentChange}
                                className="checked:accent-indigo-700 items-center rounded-md w-5 h-5"
                            />
                            <span className="ml-3 text-sm tab:text-base">PayPal</span>
                        </label>
                        <p className="mt-2 text-gray-400 text-sm">
                            You will be redirected to the PayPal website after submitting your order.
                        </p>
                    </div>
                    <img src={paypalIcon} alt="PayPal" className="bg-white px-4 py-2 rounded-md w-14 h-9" />
                </div>

                {/* Credit Card Option */}
                <div className="border-gray-500 bg-gray-900 p-4 desk:p-6 border rounded-lg">
                    <label className="flex items-center space-x-3 mb-4">
                        <input
                            type="radio"
                            value="credit-card"
                            checked={paymentMethod === "credit-card"}
                            onChange={handlePaymentChange}
                            className="checked:accent-indigo-700 rounded-md w-5 h-5"
                        />
                        <span className="text-sm tab:text-base">Pay with Credit Card</span>
                    </label>

                    {/* Credit Card Icons */}
                    <div className="flex space-x-1 mb-4">
                        {[visaIcon, discoverIcon, mastercardIcon, maestroIcon].map((icon, idx) => (
                            <img key={idx} src={icon} alt="Card Logo" className="bg-white px-1 rounded-md w-14 h-9" />
                        ))}
                    </div>

                    {/* Credit Card Fields */}
                    {paymentMethod === "credit-card" && (
                        <div className="space-y-4">
                            <div className="gap-4 grid tab:grid-cols-2">
                                <Input
                                  type="text"
                                  name="cardNumber"
                                  value={cardDetails.cardNumber}
                                  onChange={handleInputChange}
                                  placeholder="Card Number"
                                  className="focus:border-purple-600"
                                />
                                <Input
                                  type="text"
                                  name="expirationDate"
                                  value={cardDetails.expirationDate}
                                  onChange={handleInputChange}
                                  placeholder="Expiration Date"
                                  className="focus:border-purple-600"
                                />
                            </div>

                            <Input
                              type="text"
                              name="securityCode"
                              value={cardDetails.securityCode}
                              onChange={handleInputChange}
                              placeholder="Card Security Code"
                              className="focus:border-purple-600"
                            />

                            {/* Security Information */}
                            <div className="flex items-center space-x-2 mt-2">
                                <div>
                                    <IoIosLock className="p-1 border border-blue-700 rounded-full w-8 h-8 fill-blue" />
                                </div>
                                <span className="text-gray-400 text-xs tab:text-sm">
                                    We protect your payment information using encryption to provide bank-level security.
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <Button
              title="Make Payment"
              type="button"
              variant="contained"
              fullWidth={true}
              onClick={makePayment}  // Call makePayment when clicked
              className="mt-8 text-sm tab:text-lg"
            />
        </>
    );
};

export default PaymentMethod;
