import React from 'react';
import TwoColSection from '../component/CommonSection/TwoColSection';
import Code from '../component/Mainpage/Code';
import CountSection from '../component/Mainpage/CountSection';
import Hero from '../component/Mainpage/Hero';
// import BubbleAnimation from '../component/Mainpage/InnovationAnimation';
import ReadyTo from '../component/Mainpage/ReadyTo';
import YouInnovate from '../component/Mainpage/YouInnovate';
import Images1 from '../images/MainPage/image1.png';
import Images2 from '../images/MainPage/image2.png';

const MainPage = () => {
  return (
    <div className="mx-auto container">
      <Hero />
      <CountSection />
      <Code />
      <TwoColSection
        Image={Images1}
        leftHeading={'What is the'}
        colorHeading={'Polymorphic'}
        rightHeading={'Code Engine?'}
        des={
          "Polymorphic code engine dynamically mutates the software's source code in real-time, preventing reverse engineering and ensuring enhanced security."
        }
        button={''}
        type={1}
        textAlgin={1}
      />
      <div className="noneformobile">
        <TwoColSection
          Image={Images2}
          leftHeading={'How It'}
          colorHeading={'Works'}
          rightHeading={'?'}
          des={
            'By constantly changing the software code during access or execution, the  Polymorphic Code Engine creates a moving target, making it extremely  difficult for hackers to analyze or decompile.'
          }
          button={''}
          type={0}
          //   textAlgin={1}
        />
      </div>
      <div className="nonefordesktab">
        <TwoColSection
          Image={Images2}
          leftHeading={'How It'}
          colorHeading={'Works'}
          rightHeading={'?'}
          des={
            'By constantly changing the software code during access or execution, the  Polymorphic Code Engine creates a moving target, making it extremely  difficult for hackers to analyze or decompile.'
          }
          button={'Learn More'}
          type={1}
          textAlgin={1}
        />
      </div>

      <YouInnovate />
      {/* <BubbleAnimation /> */}
      <ReadyTo />
      <div />
    </div>
  );
};

export default MainPage;
