import React, { useState } from 'react';
import { IoLogoGoogle, IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { Link } from 'react-router-dom';
import Button from '../component/CommonSection/Button';
import Input from '../component/CommonSection/Input';
import PrivacyPolicyDialog from '../component/CommonSection/PrivacyDialog';
import TermsDialog from '../component/CommonSection/TermsDialog';

const LoginPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isTermsDialogOpen, setIsTermsDialogOpen] = useState(false);
  const [isPrivacyDialogOpen, setIsPrivacyDialogOpen] = useState(false);

  return (
    <>
      <div className='absolute top-0 left-0 w-full h-full bg-backdrop blur-md bg-cover bg-no-repeat bg-center'></div>
      <div>
        <div className="flex justify-center items-center px-3 py-5 sm:p-9 min-h-screen">
          <div className="relative flex flex-col items-center rounded-[40px] w-full md:max-w-[680px] h-fit overflow-hidden">
            <div className="z-0 absolute bg-[#03060e] bg-opacity-80 backdrop-blur-sm w-full h-full"></div>
            <div className="relative z-10 px-8 desk:px-20 py-8 tab:py-12">
              <div className="text-center">
                <h2 className="font-bold text-2xl desk:text-[42px] leading-[110%]">
                  Welcome Back
                </h2>
                <p className="font-montserrat text-base text-greyText desk:text-lg leading-[140%]">
                  Please log in to continue
                </p>
              </div>
              <form className="mt-8 w-full font-montserrat">
                <div className="flex flex-col gap-4">
                  <Input
                    label={'Email Address'}
                    type="email"
                    className={'focus:border-blue '}
                    placeholder={'Enter Email'}
                    fullWidth
                  />
                  <div className="relative">
                    <Input
                      label={'Password'}
                      placeholder={'Enter Password'}
                      fullWidth
                      className={'focus:border-blue !pr-8'}
                      type={showPassword ? 'password' : 'text'}
                    />
                    <span
                      onClick={() => setShowPassword(!showPassword)}
                      className="right-2 bottom-[14px] absolute cursor-pointer"
                    >
                      {!showPassword ? (
                        <IoMdEye className="w-[18px] h-[18px]" />
                      ) : (
                        <IoMdEyeOff className="w-[18px] h-[18px]" />
                      )}
                    </span>
                  </div>
                </div>
                <span className="mt-1 font-montserrat text-greyText text-xs leading-[140%]">
                  It must be a combination of minimum 8 letters, numbers, and
                  symbols. 
                </span>

                <div className="flex justify-between items-center mt-4">
                  <div className="flex flex-[0.5] justify-start items-center gap-2 w-max">
                    <Input type="checkbox" />
                    <span className="mt-2 text-sm">Remember me</span>
                  </div>
                  <div className="text-right flex-[0.5] mt-2 text-blue text-sm leading-[140%]">
                    <a href="/reset-password ">Forgot password?</a>
                  </div>
                </div>
                <Button
                  title={'Log In'}
                  variant={'contained'}
                  fullWidth
                  className={'mt-4'}
                />
              </form>

              <div className="mt-5 w-full font-montserrat text-center">
                <p className="text-greyText leading-[140%]">Or log in with :</p>
                <button className="my-4 py-3 rounded-md w-full font-semibold text-lg text-white primaryButtonBlue">
                  <div className="flex justify-center items-center gap-2">
                    <IoLogoGoogle />
                    <p>Google</p>
                  </div>
                </button>
              </div>

              <div className="flex flex-col items-center gap-3">
                <div className="flex justify-center items-center cursor-pointer">
                  <Link
                    to="/signup"
                    className="mt-5 w-full text-center text-greyText text-sm tab:text-base leading-[140%]"
                  >
                    No account yet? <b className="text-blue">Sign Up</b>
                  </Link>
                </div>
                <div>
                  <p className="text-greyText text-sm tab:text-base leading-[140%] cursor-pointer">
                    Read{' '}
                    <span onClick={() => setIsTermsDialogOpen(true)}>
                      <b className="text-blue">Terms & Conditions</b>
                    </span>{' '}
                    And{' '}
                    <span onClick={() => setIsPrivacyDialogOpen(true)}>
                      <b className="text-blue">Privacy Policies</b>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* Render TermsDialog if it is open */}
      {isTermsDialogOpen && (
        <TermsDialog
          open={isTermsDialogOpen}
          close={() => setIsTermsDialogOpen(false)}
        />
      )}
      {/* Render PrivacyPolicy Dialog if it is open */}
      {isPrivacyDialogOpen && (
        <PrivacyPolicyDialog
          open={isPrivacyDialogOpen}
          close={() => setIsPrivacyDialogOpen(false)}
        />
      )}
    </div>
    </>
  );
};

export default LoginPage;
