import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../CommonSection/Button";
const Getin = () => {
  const navigate = useNavigate();
    return(
        <div className="flex justify-center items-center my-2 desk:my-16 mb-[50px]">
      <div className="flex justify-between p-10 rounded-3xl w-full readyto" style={{background: "linear-gradient(155deg, rgb(109, 109, 109) 10%, rgb(17, 16, 29) 100%)"}}>
        <div className="w-full sm:w-full lg:w-[50%] text-center text-white desk:text-left width100at1024" style={{background:"none"}}>
        <h2 className="mb-[2rem] font-bold text-5xl" style={{background:"none"}}>
        Shashwat<span className="text-cyan-400" style={{background:"none"}}> Singh</span>
        </h2>
        <p className="mb-6 text-greyText" style={{background:"none",fontSize:"1.3rem"}}>
        ISC2-certified cybersecurity analyst specializing in cryptography and encryption algorithms. Expert in building advanced cybersecurity solutions with a proven track record of published research on security architectures.
        </p>
        </div>
        <div className="flex justify-center md:justify-start items-center" style={{background:"none"}}>
        <Button 
          title="Contact" 
          type="button"
          variant="contained"
          className="py-3"
          onClick={() => navigate('/contact')} 
        />
        </div>
        
      </div>
    </div>
    );
}

export default Getin;
