import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './component/Footer';
import BubbleAnimation from './component/Mainpage/InnovationAnimation';
import Navbar from './component/Navbar';
import About from './pages/About';
import Checkout from './pages/Checkout';
import Contact from './pages/Contact';
import ErrorPage from './pages/ErrorPage';
import LoginPage from './pages/LoginPage';
import MainPage from './pages/MainPage';
import MutaCryptorLite from './pages/MutaCryptorLite';
import MutaCryptorPro from './pages/MutaCryptorPro';
import MutaIntegri from './pages/MutaIntegri';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import Profile from './pages/Profile';
import ServicePage from './pages/ServicePage';
import SignupPage from './pages/SignupPage';
import TermsOfUsePage from './pages/TermsPage';

function App() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden bg-[#00040F]">
      <Navbar />
      <Routes>
        <Route exact path="/" element={<MainPage />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/service" element={<ServicePage />} />
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/signup" element={<SignupPage />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/MutaIntegri/checkout" element={<Checkout />} />
        <Route exact path="/MutaCryptorLite/checkout" element={<Checkout />} />
        <Route exact path="/MutaCryptorPro/checkout" element={<Checkout />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/MutaIntegri" element={<MutaIntegri />} />
        <Route exact path="/MutaCryptorLite" element={<MutaCryptorLite />} />
        <Route exact path="/MutaCryptorPro" element={<MutaCryptorPro />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route exact path="/terms-of-use" element={<TermsOfUsePage />} />
        <Route exact path="/bubble" element={<BubbleAnimation />} />
        <Route exact path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default function MainApp() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
