import SphereKnotBG from "../component/CommonSection/SphereKnotBG";
import ProfileCard from "../component/Profile/ProfileCard";

const Profile = () => {
    return (
        <div className="min-h-screen flex items-center justify-center bg-cover bg-center">
        <SphereKnotBG />
            <div className="w-full max-w-4xl mb-12">
                {/* User Profile Card */}
                <ProfileCard />
            </div>
        </div>
    );
};

export default Profile;
