import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from './Button';
const TwoColSection = (props) => {
  const navigate = useNavigate();
  if (props.type) {
    return (
      <div className="flex flex-col items-center px-4 pt-10 md:pt-12 h-full text-white">
        {props.heading && (
          <div className="flex justify-center items-center mb-[2%]">
            <div
              className="border-2 shadow-lg px-8 py-4 border-blue rounded-lg text-4xl text-white"
              style={{ boxShadow: 'rgba(0, 255, 255, 0.5) 0px -2px 4px 1px',borderRadius:"16px" }}
            >
              {props?.heading}
            </div>
          </div>
        )}
        {/* Flex-col for mobile and tablet, row for desktop */}
        <div className="flex lg:flex-row flex-col-reverse items-center w-[80%]">
          <div className="flex justify-start my-10 w-full lg:w-1/2">
            <img
              src={props?.Image}
              alt=""
              className={`rounded-lg w-full h-full max-w-[450px]`}
              // max-w-[370px] max-h-[450px]
            />
          </div>
          <div className="flex flex-col items-center desk:items-start gap-10 px-4 w-full lg:w-1/2 text-center lg:text-left pr-[0]">
            {(props.leftHeading ||
              props.colorHeading ||
              props.rightHeading) && (
              <h1 className="font-bold text-3xl sm:text-4xl lg:text-5xl">
                {props.leftHeading}{' '}
                <span className="text-blue">
                  <br />
                  {props.colorHeading}
                </span>{' '}
                {props.rightHeading}
              </h1>
            )}
            <p className="font-montserrat text-base text-center text-greyText lg:text-justify lg:text-xl">
              {props?.des}
            </p>
            {props.button && (
              <Button
                title={props.button}
                type="button"
                variant="outlined"
                conicGradient={props.conicGradient}
                onClick={
                  props.redirect ? () => navigate(`/${props.redirect}`) : null
                }
              />
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col justify-start items-center px-4 pb-10 md:pb-20 text-white">
        {props.heading && (
          <div className="flex justify-center items-center mb-[2%]">
            <div
              className="border-2 shadow-lg px-8 py-4 border-blue rounded-lg text-4xl text-white"
              style={{ boxShadow: 'rgba(0, 255, 255, 0.5) 0px -2px 4px 1px',borderRadius:"16px" }}
            >
              {props.heading}
            </div>
          </div>
        )}
        {/* Flex-col for mobile and tablet, row for desktop */}
        <div className="flex lg:flex-row flex-col-reverse items-center gap-8 lg:gap-0 w-[80%]">
          <div className="flex flex-col items-center lg:items-end gap-5 px-4 w-full lg:w-1/2 text-center desk:text-end pl-[0]">
            {(props.leftHeading ||
              props.colorHeading ||
              props.rightHeading) && (
              <span className="font-bold text-3xl sm:text-4xl lg:text-5xl">
                {props.leftHeading}{' '}
                <span className="text-blue">{props.colorHeading}</span>{' '}
                {props.rightHeading}
              </span>
            )}
            <p
              className={`text-gray-300 Montserrat text-base lg:text-xl text-center lg:text-right `}
            >
              {props?.des}
            </p>
            {props.button && (
              <Button
                title={props.button}
                type="button"
                variant="outlined"
                conicGradient={props.conicGradient}
                onClick={
                  props.redirect ? () => navigate(`/${props.redirect}`) : null
                }
              />
            )}
          </div>
          <div className="flex flex-1 justify-end my-10 w-full">
            <img
              src={props.Image}
              alt=""
              className={`rounded-lg max-w-[450px] w-full h-full`}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default TwoColSection;
