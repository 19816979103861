import { IoIosArrowForward } from "react-icons/io";
import { Link } from 'react-router-dom';
import bgImage from '../images/backdrop.png';

const ErrorPage = () => {
    return (
        <>
            <img
                src={bgImage}
                alt="error"
                className="top-0 left-0 absolute blur-lg w-full h-full object-cover"
            />

            {/* 404 Text in the Background */}
            <div className="absolute inset-0 flex justify-center items-center">
                <h1 className="opacity-25 blur-[12px] font-bold text-[10rem] text-white desk:text-[20rem] tab:text-[15rem] select-none">
                    404
                </h1>
            </div>

            <div className="relative z-10 flex flex-col justify-center items-center px-2 min-h-screen">
                <h1 className="mb-4 font-bold text-6xl tab:text-8xl">Whoops!</h1>
                <p className='text-center text-gray-300'>The page you're looking for is unavailable</p>
                <p className='text-center text-gray-300'>The link may be broken or the page may've been moved</p>
                <Link to="/" className='pt-8 font-bold text-2xl text-blue'>
                    Go Home <IoIosArrowForward className='inline fill-blue' />
                </Link>                
            </div>
        </>
    );
};

export default ErrorPage;
